export enum MixpanelEvents {
  clickEmailRegister = 'Click Email register',
  clickGoogleRegister = 'Click Google register',
  visitRegistrationPage = 'Visit Registration Page',
  clickLogin = 'Click Login',
  fillEmail = 'Fill Email',
  clickSendOTP = 'Click send OTP',
  fillOTP = 'Fill OTP',
  clickVerify = 'Click verify',
  verificationStatus = 'Verification Status',
  visitPreparingPage = 'Visit Preparing Page',
  clickSubmitStoreName = 'Click submit store name',
  submitMobileNumber = 'Submit mobile number',
  fillCategory = 'Fill Category',
  submitCategory = 'Submit Category',
  chooseLocationType = 'Choose Location type',
  submitLocation = 'Submit Location',
  chooseBusinessType = 'Choose business type',
  clickCreateStore = 'Click Create store',
  visitSubscriptionPage = 'Visit subscription page',
  choosePackageDuration = 'Choose Package Duration',
  choosePackage = 'Choose Package',
  visitPaymentPage = 'Visit payment page',
  completePayment = 'Complete payment',
  clickEnglishLanguage = 'Click English language',
  clickArabicLanguage = 'Click Arabic language',
  visitOrdersPage = 'Visit Orders Page',
  clickDrafts = 'Click Drafts',
  clickOrdersStatusMessages = 'Click Orders status messages',
  clickCreateNewOrder = 'Click Create new order',
  clickAnalytics = 'Click Analytics',
  chooseOrdersFilter = 'Choose orders filter',
  chooseNumberOfOrdersDisplayedFilter = 'Choose number of orders displayed filter',
  searchFilter = 'Search filter',
  clickExportSllOrders = 'Click Export all orders',
  cancelExportOrders = 'Cancel export orders',
  editEmailExportOrders = 'edit email - export orders',
  confirmExportOrders = 'Confirm export orders',
  clickPrintWaybill = 'Click print waybill',
  clickChangeOrderStatus = 'Click Change order status',
  clickChangePaymentStatus = 'Click Change payment status',
  clickDetermineDateOfReceivingTheOrder = 'Click determine date of receiving the order',
  selectOrder = 'Select order',
  openOrderDetailesSlideCard = 'open order detailes slide card',
  clickOrderId = 'click order id',
  viewDraftsPage = 'view drafts page',
  clickAddNewDraft = 'Click add new draft',
  viewAddingProductPage = 'View adding product page',
  searchProduct = 'search product',
  addSelectedProducts = 'add selected products',
  changeQuantity = 'change quantity',
  removeProduct = 'remove product',
  clickNext = 'Click next',
  viewCustomerInformationPage = 'View customer information page',
  searchCustomer = 'search customer',
  addNewCustomer = 'add new customer',
  addNewAddress = 'add new address',
  chooseAddress = 'choose address',
  chooseDifferentOrderReciever = 'choose different order reciever',
  viewPaymentShippingMethodPage = 'View payment & shipping method page',
  selectPaymentMethod = 'select payment method',
  viewOrderSummaryPage = 'View Order Summary page',
  clickConfirmation = 'Click confirmation',
  visitProductPage = 'Visit Product Page',
  clickProductFiltrationSettings = 'Click Product Filtration Settings',
  visitProductFiltrationSettingsPage = 'Visit Product Filtration Settings page',
  clickProductCategories = 'Click Product Categories',
  visitProductCategories = 'Visit Product Categories',
  chooseAddNewProduct = 'Choose add new product',
  chooseAddNewDigitalProduct = 'Choose add new digital product',
  clickNormalProductsView = 'Click normal products view',
  clickDigitalProductsView = 'Click digital products view',
  clickArrangeManually = 'Click Arrange Manually',
  clickFilterProducts = 'Click Filter Products',
  chooseExportSelectedProducts = 'Choose Export selected products',
  chooseExportAllProducts = 'Choose Export all products',
  chooseEditSelectedProducts = 'Choose Edit selected products',
  chooseEditSllProducts = 'Choose Edit all products',
  chooseDeleteProducts = 'Choose delete products',
  selectAProduct = 'Select a product',
  chooseDuplicateAProduct = 'Choose duplicate a product',
  chooseDeleteAProduct = 'Choose delete a product',
  chooseCustomizeSimilarProducts = 'Choose customize similar products',
  chooseEditAProduct = 'Choose Edit a product',
  clickOnAProduct = 'Click on a product',
  clickImportProducts = 'Click import products',
  clickCustomerName = 'Click customer name',
  clickProductsSales = 'Click Products sales',
  openProductInfoCard = 'Open product info card',
  globalSearch = 'Global search',
  clickExportProducts = 'Click Export Products',
  clickAddNewProduct = 'Click add new product',
  clickMoreAction = 'Click more action',
  clickOnaProductActions = 'Click on a product actions',
  clickVerifyEmailOTP = 'Click verify Email OTP',
  clickVerifyPhoneOTP = 'Click verify Phone OTP',
  clickZamMainHelpCenter = 'Click ZAM main help center',
  clickZamMainAppsManagement = 'Click ZAM main apps management',
  clickZamMainAppsSearch = 'Click ZAM main apps search',
  clickZamScrollFeaturedApps = 'ZAM scroll featured apps',
  clickZamFeaturedApp = 'Click ZAM featured app',
  clickZamScrollAppTestimonials = 'ZAM scroll app testimonials',
  clickZamClickAppTestimonial = 'ZAM click app testimonial',
  clickZamAllCategories = 'Click ZAM all categories',
  clickZamViewCategory = 'Click ZAM view category',
  clickZamViewCategories = 'Click ZAM browse all categories',
  clickZamChoiceApp = 'Click ZAM application card',
  clickZamSuggestApp = 'Click ZAM suggest app',
  clickZamPartnerRegisteration = 'Click open partner Partner Dashboard',
  enableZAMRecurring = 'Enable ZAM App recurring',
  clickZamBrowseAppsFilters = 'Click ZAM browse apps filters',
  clickZamBrowseApps = 'Click ZAM browse apps',
  clickZamActivateApp = 'Click ZAM activate app',
  clickZamActivateFreeApp = 'Click ZAM activate free app',
  clickZamDeactivateApp = 'Click ZAM deactivate app',
  clickZamSearchAppCategory = 'Click ZAM search by app',
  sortZamApps = 'Sort ZAM apps',
  clickZamAppViewPlans = 'Click ZAM app view plans',
  clickZamAppViewReviews = 'Click ZAM app view reviews',
  clickZamViewSubscriptionPlans = 'Click ZAM app view subscription plans',
  clickZamSubscribeToTrialPlan = 'Click ZAM subscribe to free trial',
  clickZamSubscribeToFreePlan = 'Click ZAM subscribe to free plan',
  clickZamSubscribeToPlan = 'Click ZAM subscribe to plan',
  enableZAMPlanRecurring = 'Enable ZAM Plan recurring',
  clickZamCompletePayment = 'Click ZAM complete payment',
  clickZamUpgradePlan = 'Click ZAM upgrade plan',
  clickZamOpenApp = 'Click ZAM open app',
  clickZamReportAppIssueConfirm = 'Click ZAM report app issue confirm',
  clickZamReportAppIssue = 'Click ZAM report app issue',
  rateZamApp = 'Rate ZAM app',
  clickZamScrollAppCollections = 'ZAM scroll app collections',
  clickZamViewCollection = 'Click ZAM view collection',
  zamPaymentSuccess = 'ZAM payment success',
  zamPaymentFailure = 'ZAM payment failure',
  zamApproveScopesAndActivateApp = 'ZAM Approve Scopes And Activate Application',
  zamApproveScopesAndMoveToPayment = 'ZAM Approve Scopes And Move To Payment',
  zamShowSubscriptionModal = 'View ZAM purchase modal',
  zamUpgradePlanBtn = 'Click ZAM upgrade plan button',
  zamCancelAppActivation = 'ZAM cancel app activation',
  zamOpenZAMHome = 'ZAM view home page',
  zamScrollZAMHomeBanner = 'ZAM scroll banner',
  zamScrollZAMHomeCollection = 'ZAM scroll collection',
  zamScrollZAMHomeFeaturedApps = 'ZAM scroll featured apps',
  zamClickZAMBanner = 'Click ZAM banner card',
  productsFilterByInventory = 'Products Filter by inventory',
  productsFilterByPublishingStatus = 'Products Filter by publishing status',
  productsFilterByCategories = 'Products Filter by category',
  productsFilterByAvailability = 'Products Filter by availability',
  productsFilterByDiscountStatus = 'Products Filter by discount status',
  productsFilterByPrice = 'Products Filter by price',
  productsFilterByAvailableQuantity = 'Products Filter by available quantity',
  clickStartBusiness = 'Click Start Business',
  clickDemo = 'Click demo',
  sendNewOtpCode = 'Send new OTP code',
  loginWithPassword = 'Login with password',
  changeEmail = 'Change email',
  clickForgotPassword = 'Click Forgot Password',
  clickLoginwithOTP = 'Login with OTP',
  answerPhysicalOrDigitalProductsQuestion = 'Physical or Digital Products Question',
  clickPhysicalOrDigitalProductsQuestionPhysicalAnswer = 'Physical or Digital Products Question "Physical" Answer',
  clickPhysicalOrDigitalProductsQuestionDigitalAnswer = 'Physical or Digital Products Question "Digital" Answer',
  clickPhysicalOrDigitalProductsQuestionBothAnswer = 'Physical or Digital Products Question "Both" Answer',
  clickPhysicalOrDigitalProductsQuestionNoProductsYetAnswer = 'Physical or Digital Products Question "I don’t have products yet" Answer',
  answerSaleChannelsQuestion = 'Where do you want to sell Question',
  clickSaleChannelsQuestionOfflineStoreAnswer = 'Where do you want to sell Question “Offline store”',
  clickSaleChannelsQuestionOfflineAtEventsAnswer = 'Where do you want to sell Question “Offline at events”',
  clickSaleChannelsQuestionOnlineMarketplaceAnswer = 'Where do you want to sell Question “Online Marketplace e.g. Amazon”',
  clickSaleChannelsQuestionSocialMediaAnswer = 'Where do you want to sell Question “Social Media”',
  clickSaleChannelsQuestionOnlineStoreAnswer = 'Where do you want to sell Question “Online Store”',

  answerMigrateStoreQuestion = 'Would you like help switching to Zid Question',
  clickMigrateStoreQuestionYesAnswer = 'Would you like help switching to Zid Question “Yes”',
  clickMigrateStoreQuestionNoAnswer = 'Would you like help switching to Zid Question “No”',
  answerPlatformToMigrateFromQuestion = 'Which platform are you using Question',
  clickPlatformToMigrateFromQuestionSallaAnswer = 'Which platform are you using Question “Salla”',
  clickPlatformToMigrateFromQuestionShopifyAnswer = 'Which platform are you using Question “Shopify”',
  clickPlatformToMigrateFromQuestionMakaneAnswer = 'Which platform are you using Question “Makane”',
  clickPlatformToMigrateFromQuestionExpandCartAnswer = 'Which platform are you using Question “Expand Cart”',
  clickPlatformToMigrateFromQuestionOtherAnswer = 'Which platform are you using Question “Other”',
  answerMonthlySalesVolumeQuestion = 'Average monthly sales revenue Question ',
  clickMonthlySalesVolumeQuestionLessThan40KAnswer = 'Average monthly sales revenue Question “Small < 40K SAR”',
  clickMonthlySalesVolumeQuestion40To399KAnswer = 'Average monthly sales revenue Question “Medium 40K - 399K SAR”',
  clickMonthlySalesVolumeQuestion400To1MAnswer = 'Average monthly sales revenue Question “Large 400K - 1M SAR”',
  clickMonthlySalesVolumeQuestionMoreThan1MAnswer = 'Average monthly sales revenue Question “Enterprise 1M SAR <”',
  answerZidSupportForMerchantQuestion = 'What can Zid do for you Question',
  clickZidSupportForMerchantQuestionSetupOnlineStoreAnswer = 'What can Zid do for you Question “Set up Online Store”',
  clickZidSupportForMerchantQuestionOfflineStoreSupportAnswer = 'What can Zid do for you Question “Offline Store Support”',
  clickZidSupportForMerchantQuestionShippingAndPaymentAnswer = 'What can Zid do for you Question “Shipping and Payment Options”',
  clickZidSupportForMerchantQuestionGrowthAndMarketingAnswer = 'What can Zid do for you Question “Growth and Marketing”',
  clickZidSupportForMerchantQuestionAnalyticsAndInsightsAnswer = 'What can Zid do for you Question “Analytics and Insights”',
  clickZidSupportForMerchantQuestionMultiCurrencyAndMultilingualAnswer = 'What can Zid do for you Question “Multi-currency and Multilingual Support”',
  clickZidSupportForMerchantQuestionMarketplaceIntegrationAnswer = 'What can Zid do for you Question “Marketplace Integration e.g. Amazon”',
  answerMerchantDescriptionQuestion = 'What best describes you Question',
  clickMerchantDescriptionQuestionSoloEntrepreneurAnswer = 'What best describes you Question “Solo Entrepreneur”',
  clickMerchantDescriptionQuestionTeamManagerAnswer = 'What best describes you Question “Team Manager”',
  clickMerchantDescriptionQuestionCxoOrVpAnswer = 'What best describes you Question “CxO/VP”',
  clickMerchantDescriptionQuestionCuriousAnswer = 'What best describes you Question “Just Curious”',
  clickMerchantDescriptionQuestionCreatingForClientAnswer = 'What best describes you Question “Setting up for a client”',
  clickNextToSubmitAnswers = 'Click Next to Submit Answers',
  changePhoneNumberCountryCode = 'Change Phone Number Country Code',
  sendNewCode = 'Send New Code',
  changePhoneNumber = 'Change Phone Number',
  fillAddressAutomatically = 'Fill Address Automatically',
  fillAddressManually = 'Fill Address Manually',
  undoSettingAddress = 'Undo Setting Address',
  clickLogoutArrow = 'Click Logout Arrow',
  signOut = 'Sign Out',
  undoSigningOut = 'Undo Signing Out',
  changeEmailForLoginFlow = 'Change Email (Login Flow)',
  clickVerifyForLoginFlow = 'Click verify (Login Flow)',
  clickVerifyEmailOTPForLoginFlow = 'Click verify Email OTP (Login Flow)',
  sendNewOtpCodeForLoginFlow = 'Send new OTP code (Login Flow)',
  loginWithPasswordForLoginFlow = 'Login with password (Login Flow)',
  resetPassword = 'Reset Password',
  clickLoginByGoogle = 'Click Login by Google',
  zidshipPageView = 'zidship page view',
  zidshipActivateSL = 'Activate SL',
  zidshipDeactivateSL = 'Deactivate SL',
  zidshipServicesCollectionaPageView = 'View services collections',
  zidshipUpdateInventory = 'Update inventory',
  zidshipCustomizeSL = 'Customize SL',
  zidshipUpdateCustomizeSL = 'Update customize SL',
  zidshipAddPrepaidPackage = 'Add to cart prepaid package',
  zidshipRemovePrepaidPackage = 'Remove from cart prepaid package',
  zidshipBeginCheckoutPrepaidPackage = 'Begin checkout prepaid package',
  ZidshipCompletePurchasePrepaidPackage = 'Complete purchase prepaid package',
  visitFinancing = '[Financing] Visit Financing  marketing page',
  clickFinancingCalculator = '[Financing] Click on finance calculator ',
  calculateFinancingAmount = '[Financing] Click on Calculate the amount',
  registerFinancingInterests = '[Financing] Click on register your interest',
  viewFinancingPersonalInfo = '[Financing] View personal info',
  clickNextAfterFinancingPersonalInfo = '[Financing] Click on next - (Financing) personal info ',
  clickCancelAfterFinancingPersonalInfo = '[Financing] Click on cancel - (Financing) personal info ',
  viewFinancingAdressPage = '[Financing] View address page',
  clickNextFromFinancingAdressPage = '[Financing] Click next - address page ',
  clickPreviousFromFinancingAdressPage = '[Financing] Click previous - address page ',
  clickCancelFromFinancingAddressPage = '[Financing] Click cancel application - address page  ',
  viewFinancingBusinessPage = '[Financing] View business information',
  clickNextFromFinancingBusinessInfo = '[Financing] Click next - Business information',
  clickPreviousFromFinancingBusinessInfo = '[Financing] Click previous - Business information',
  clickCancelFromFinancingBusinessInfo = '[Financing] Click cancel application  - Business information',
  viewFinancingRequiredDocs = '[Financing] View page - Required Documents',
  clickNextFinancingRequiredDocs = '[Financing] Click next  - Required Documents',
  clickPreviousFromFinancingRequiredDocs = '[Financing] Click previous - Required Documents',
  clickCancelFromFinancingRequiredDoc = '[Financing] Click cancel application - Required Documents',
  viewFinancialStorePerformance = '[Financing] View financial Store Performance',
  clickSubmitFinanceApplication = '[Financing] Click submit Finance Application  - Financial Store Performance',
  clickPreviousFinanceApplication = '[Financing] Click pervious - Financial Store Performance',
  clickCancelFinancialStorePerformance = '[Financing] Click cancel application -  Financial Store Performance',
  viewFundingApplicationStatus = '[Financing] View funding application status',
  clickContinueFinanceApplication = '[Financing] Click continue Finance Application',
  clickCancelFinanceApplication = '[Financing] Click cancel Finance Application',
  viewDownloadPriceOffer = '[Financing] View download the price offer',
  clickNextDownloadPriceOffer = '[Financing] Click next - Download the price offer',
  clickCancelDownloadPriceOffer = '[Financing] Click cancel Finance Application - Download the price offer',
  viewBankInformation = '[Financing] View Bank information',
  clickNextBankInformation = '[Financing] Click next - Bank information',
  clickPreviousBankInformation = '[Financing] Click previous - Bank information',
  clickCancelBankInformation = '[Financing] Click cancel Finance Application - bank information',
  viewDebtorSponsor = '[Financing] View information of the debtor sponsor',
  clickNextDebtorSponsor = '[Financing] Click next - Information of the debtor sponsor',
  clickPreviousDebtorSponsor = '[Financing] Click previous - Information of the debtor sponsor',
  clickCancelDebtorSponsor = '[Financing] Click cancel Finance Application - Information of the debtor sponsor',
  viewOtherRequirements = '[Financing] View Other requirments',
  clickSubmitOtherRequirements = '[Financing] Click Submit financing requirements - Other requirments',
  clickPreviousOtherRequirments = '[Financing] Click previous - Other requirments',
  clickCancelFinanceApplicationForOtherRequirments = '[Financing] Click cancel finance application - Other requirments',
  viewPopupOfSubmitFinalOtherRequirements = '[Financing] View popup of Submit final Requirements OR our financing requirements have been submitted successfully page',
  viewMerchantDataForm = '[Financing] View Merchant data form',
  clickOnSubmitMerchantDataForm = '[Financing] Click on Submit financing requirements - Merchant data form',
  viewPopupOfFundingApplicationStatus = '[Financing] View popup of Funding application status OR Funding application status page',
  viewContractSigning = '[Financing] View Contract Signing',
  clickOnReservationConfirmation = '[Financing] Click on Reservation Confirmation',
  clickOnSubmitRequirementFundingApplication = '[Financing] Click on submit requirement - Funding application status',
  viewPopupSubmitRequirement = '[Financing] View popup submit requirement - Funding application status',
  viewZidFinanceLandingPage = '[Financing] View ZidFinance Landing Page (Deposited)',
  viewThemeMarket = 'View Theme Market',
  viewTheme = 'Click View Theme Details from Card',
  previewTheme = 'Click Preview Theme from Card',
  customizeTheme = 'Click Customize Theme from Card',
  scrollThemeTestimonials = 'Scroll Theme Testimonials',
  discoverThemes = 'Click Discover All Themes',
  addCustomThemeSDK = 'Click Add Custom SDK',
  activateCustomSDK = 'Click ActivateCustom SDK',
  deactivateCustomSDK = 'Click Deactivate Custom SDK',
  customizeCustomSDK = 'Click Customize Custom SDK',
  applyThemeOnStore = 'Click Apply Theme on Store',
  previewThemeFromDetails = 'Click Preview Theme from Theme Details',
  customizeThemeFromDetails = 'Click Customize Theme from Theme Details',
  editThemeCSS = 'Click Edit Theme CSS',
  openThemeSupport = 'Click Open Theme Support',
  reportThemeIssue = 'Click Report Theme Issue',
  rateTheme = 'Click Rate Theme',
  clickSuggestedTheme = 'Click Suggested Theme',
  customizeActiveTheme = 'Click Customize Active Theme',
  viewStoreIdentity = 'Click View Store Identity',
  editIdentitySettings = 'Click Edit Identity Settings',
  editStoreCopyrights = 'Click Edit Store Copyrights',
  viewMenuSettings = 'Click View Menu Settings',
  editMenuSettings = 'Click Edit Menu Settings',
  viewExtraPagesSettings = 'Click View Extra Pages Settings',
  addExtraPage = 'Click Add Extra Page',
  addNewPage = 'Click Add New Page',
  visitUserManagmentPage = 'Visit User Managment Page',
  visitUserManagmentPageGroup = 'Visit User Managment Page (group tab)',
  createNewGroupButton = 'Create New Group (button)',
  clickOnAddNewMember = 'Click on Add New member',
  creatNewGroupGroupPage = 'Creat New Group (group page)',
  editPermissionroupEditGroupPage = 'Edit Permission Group',
  clickOnGiveFullPermissionGroupPage = 'Click on give full permission  (Group page)',
  clickOnGiveFullPermissionEditGroupPage = 'Click on give full permission  (Edit group page)',
  clickOnGiveFullPermissionUserPage = 'Click on give full permission  (User page)',
  clickOnGiveFullPermissionEditUserPage = 'Click on give full permission  (Edit user page)',
  clickOnAddNewMembersToAGroup = 'Click on add new members to a group',
  clickOnConfirmGroup = 'Click on Confirm (group page)',
  clickOnAddUser = 'Click on add user ',
  clickOnAddNewCashierUser = 'Click on Add new Cashier user',
  clickOnAddUserToAGroup = 'Click on Add user to a group',
  clickOnAddAnotherUser = 'Click on add another user',
  clickOnEditUser = 'Click on edit user',
  clickOnDeleteUser = 'Click on delete user',
  clickOnDeleteGroup = 'Click on delete group',
  clickOnEditStoreManagerDetails = 'Edit ZidGrowth Store Manager Details',
  clickOnSaveStoreManagerDetails = 'Save ZidGrowth Store Manager Details',
  requestToJoinACommunity = 'Request to Join a Community',
  whyIDontHaveAnRM = 'Why i Don’t Have an RM',
  purchaseAZidGrowthService = 'Purchase a ZidGrowth Service',
  changeZidGrowthServiceLevel = 'Change ZidGrowth Service Level',
  completeZidGrowthServicePurchase = 'Complete ZidGrowth Service Purchase',
  rateZidGrowthService = 'Rate ZidGrowth Service',
  useDatepickerZidGrowthAnalytics = 'Use Datepicker ZidGrowth Analytics',
  enableZidGrowthAnalyticsComparison = 'Enable ZidGrowth Analytics Comparison',
  changeZidGrowthAnalyticsGraphType = 'Change ZidGrowth Analytics Graph Type',
  viewAllAnalyticsZidGrowth = 'View All Analytics - ZidGrowth',
  viewZidGrowthYearlyGoal = 'View ZidGrowth Yearly Goal',
  editGoalZidGrowth = 'Edit Goal - ZidGrowth',
  saveNewGoalsZidGrowth = 'Save New Goals - ZidGrowth',
  changeYearForGoalsZidGrowth = 'Change Year for Goals - ZidGrowth',
  changeMonthForGoalsZidGrowth = 'Change Month for Goals - ZidGrowth',
  directIntegrationForZidGrowthPlatforms = 'Direct Integration for ZidGrowth Platforms',
  useZidGrowthPlatformsDatepicker = 'Use ZidGrowth Platforms Datepicker',
  refreshZidGrowthPlatformsMetrics = 'Refresh ZidGrowth Platforms Metrics',
  editAccountsZidGrowth = 'Edit Accounts - ZidGrowth',
  addAccountZidGrowth = 'Add Account - ZidGrowth',
  chooseAdAccountZidGrowth = 'Choose Ad Account - ZidGrowth',
  linkedAccountsZidGrowth = 'Linked Accounts - ZidGrowth',
  howToBenefitFromMarketingCalendar = 'How to Benefit from Marketing Calendar',
  answerScheduleCallQuestion = 'Schedule a call with one of our agents Question',
  clickScheduleCallQuestionMorningAnswer = 'Call with one of our agents Question “Morning (9 AM - 11 AM)”',
  clickScheduleCallQuestionAfternoonAnswer = 'Call with one of our agents Question “Afternoon (12 PM - 4 PM)”',
  clickScheduleCallQuestionEveningAnswer = 'Call with one of our agents Question “Evening (5 PM - 8 PM)”',
  clickScheduleCallQuestionNoScheduleAnswer = "Call with one of our agents Question “No, I'll schedule it later”",
  answerStoreLaunchTimeframeQuestion = 'Time to launch your store Question',
  clickStoreLaunchTimeframeQuestionASAPAnswer = 'Time to launch your store Question “As soon as possible”',
  clickStoreLaunchTimeframeQuestion1To3MonthsAnswer = 'Time to launch your store Question “1 - 3 months”',
  clickStoreLaunchTimeframeQuestion3To6MonthsAnswer = 'Time to launch your store Question “3 - 6 months”',
  answerScheduleCallForSwitchingQuestion = 'Call with one of our agents for switching Question',
  clickScheduleCallForSwitchingQuestionMorningAnswer = 'Call with one of our agents for switching Question “Morning (9 AM - 11 AM)”',
  clickScheduleCallForSwitchingQuestionAfternoonAnswer = 'Call with one of our agents for switching Question “Afternoon (12 PM - 4 PM)”',
  clickScheduleCallForSwitchingQuestionEveningAnswer = 'Call with one of our agents for switching Question “Evening (5 PM - 8 PM)”',
  clickScheduleCallForSwitchingQuestionNoAnswer = "Call with one of our agents for switching Question “No, I'll schedule it later”",
  addFirstName = 'Add First Name',
  addLastName = 'Add Last Name',
  confirmAddingFirstLastNameRegistrationFlow = 'Confirm Adding First & Last Name (Registration Flow)',
  visitZidGrowthPage = 'Visit ZidGrowth Page',
}
