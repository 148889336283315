export enum UserPermissionSlugEnum {
  storeCustomization = 'theme.market.store.identity.manage',
  editOrders = 'orders.edit',
  settingsStore = 'store.info.view',
  manageStoreInfo = 'store.info.manage',
  staffNotifications = 'manage.store.staff.notifications',
  coupons = 'coupons.view',
  addCoupon = 'coupons.manage',
  viewCoupon = 'coupons.view',
  editCoupon = 'coupons.manage',
  couponsReports = 'coupons.view',
  couponsManage = 'coupons.manage',
  discountRules = 'automatic.discounts.view',
  bundleOffers = 'bundle.offers.view',
  bundleOfferCreation = 'bundle.offers.manage',
  bundleOfferEdit = 'bundle.offers.manage',
  home = 'view.store.home',
  landingPages = 'landing.pages.manage',
  landingPagesCreation = 'landing.pages.manage',
  landingPagesEdit = 'landing.pages.manage',
  reports = 'view.store.dashboard',
  analyticsV2 = 'view.store.dashboard',
  analyticsReports = 'view.store.dashboard',
  liveAnalytics = 'view.store.dashboard',
  orderDrafts = 'orders.create',
  orders = 'orders.view',
  ordersExport = 'orders.export',
  orderNotifications = 'orders.manage.order.status.messages',
  orderNotificationsPricing = 'orders.manage.order.status.messages',
  orderDetails = 'orders.edit',
  reverseOrder = 'orders.manage.status',
  ordersInvoices = 'orders.view',
  vatSettings = 'vat.settings.view',
  orderCreation = 'orders.create',
  orderStatusMessages = 'orders.manage.status.messages',
  orderDraft = 'orders.create',
  paymentOptions = 'payment.options.view',
  otherPaymentOptions = 'payment.options.manage',
  thirdPartyProviderDetails = 'payment.options.manage',
  products = 'products.view',
  productOption = 'products.edit.delete',
  optionsLibrary = 'products.view',
  similarProducts = 'products.view',
  createProduct = 'products.create',
  createDonation = 'products.create',
  editProduct = 'products.view',
  bulkEdit = 'products.edit.delete',
  productsExport = 'products.view',
  donation = 'products.view',
  donations = 'products.view',
  editDonation = 'products.edit.delete',
  productsStockHistory = 'products.view.quantity.modification.record',
  subscription = 'subscription.manage',
  subscriptionsCheckout = 'subscription.manage',
  subscriptionsCheckoutThank = 'subscription.manage',
  subscriptionsIndex = 'subscription.view',
  subscriptionPurchases = 'subscription.view',
  subscriptionPackages = 'subscription.manage',
  subscriptionAddons = 'subscription.view',
  subscriptionSavedCards = 'subscription.manage',
  subscriptionSaveCard = 'subscription.manage',
  storeSettings = 'manage.store.settings',
  customers = 'customers.view',
  customer = 'customers.view',
  customerAddresses = 'customers.view',
  customersExport = 'customers.export',
  customersGroups = 'customers.view',
  customersGroup = 'customers.view',
  deliveryOptions = 'manage.store.ecosystem.delivery',
  zidShip = 'zidship.view',
  zidshipServiceLevelCustomization = 'zidship.view&zidship.view.services',
  zidshipPrint = 'zidship.print',
  zidShipExports = 'zidship.exports',
  zidshipServiceLevelDetails = 'zidship.view&zidship.view.services',
  zidshipShipments = 'zidship.view&zidship.view.shipment',
  zidShipWallet = 'zidship.view',
  zidshipWaybillsPackages = 'zidship.view&zidship.view.waybills.packages',
  zidShipMerchantReports = 'zidship.view&zidship.view.reports',
  zidShipSettings = 'zidship.manage.settings',
  zidshipAccountSettings = 'zidship.view&zidship.manage.settings',
  zidshipHelpCenter = 'zidship.view',
  zidshipAddPricingZone = 'zidship.view&zidship.view.services',
  zidshipEditPricingZone = 'zidship.view&zidship.view.services',
  merchantReports = 'manage.store.ecosystem.delivery,zidship.view.reports',
  domains = 'store.domain.view',
  settingsCurrentDomain = 'store.domain.manage',
  settingsLinkExistingDomain = 'store.domain.manage',
  storePages = 'manage.store.front.pages',
  categories = 'products.categories.manage',
  category = 'products.categories.manage',
  zidAcademy = 'view.zid.academy',
  fulfilment = 'manage.store.ecosystem.orderfulfillment',
  photography = 'manage.store.ecosystem.photographics',
  design = 'manage.store.ecosystem.designs',
  selfStorage = 'manage.store.ecosystem.selfstorage',
  marketing = 'manage.store.ecosystem.marketing',
  packaging = 'manage.store.ecosystem.packaging',
  coworkingSpaces = 'manage.store.ecosystem.workplaces',
  prints = 'manage.store.ecosystem.printings',
  moderators = 'view.store.home',
  mobileAppLandingPage = 'view.store.home',
  mobileAppManager = 'zam.mobile.app',
  mobileAppManagerPushNotifications = 'zam.mobile.app',
  mobileAppManagerDiscounts = 'zam.mobile.app',
  mobileAppManagerBanner = 'zam.mobile.app',
  smsPricing = 'sms.campaigns.manage',
  seo = 'seo.manage',
  fosteringNgo = 'fostering.charity.view.edit',
  abandonedCarts = 'abandoned.carts.view',
  abandonedCartsDetails = 'abandoned.carts.manage',
  abandonedCartsExport = 'abandoned.carts.export',
  zidpay = 'zidpay.view',
  zidpayMarketing = 'zidpay.view',
  zidpayActivation = 'zidpay.view',
  zidpayActivationConfirmation = 'zidpay.view',
  zidpayTransactions = 'zidpay.view&zidpay.view.transaction.list',
  zidpayTransaction = 'zidpay.view&zidpay.view.transaction.list',
  zidpayPaymentLink = 'zidpay.view&zidpay.view.payment.links',
  zidpayPaymentLinks = 'zidpay.view&zidpay.view.payment.links',
  zidpayManagePaymentLinks = 'zidpay.view&zidpay.manage.payment.links',
  zidpayDeposits = 'zidpay.view&zidpay.view.deposits',
  zidpayDeposit = 'zidpay.view&zidpay.view.deposits',
  zidpayCreateRefund = 'zidpay.view&zidpay.request.refunds',
  zidpaySettings = 'zidpay.view&zidpay.manage.settings',
  zidpayExport = 'zidpay.exports',
  finance = 'zid.financing.view',
  financeMarketing = 'zid.financing.view',
  financeActivation = 'zid.financing.manage',
  financeLanding = 'zid.financing.view',
  financeSoon = 'zid.financing.view',
  mazeed = 'core.mazeed.view',
  mazeedSettings = 'core.mazeed.manage',
  mazeedProductsUpdates = 'core.mazeed.manage',
  mazeedReportsSection = 'core.mazeed.manage',
  mazeedOrdersSection = 'core.mazeed.manage',
  mazeedSettlementInvoice = 'core.mazeed.manage',
  mazeedSettlements = 'core.mazeed.manage',
  mazeedExportTransactions = 'core.mazeed.export',
  appMarketAppSubscribe = 'zam.app.subscriptions',
  appMarketInstallJSApp = 'zam.install.js.app',
  appMarketEmbedded = 'zam.embedded.app.view',
  appMarketAccessEmbeddedApp = 'zam.embedded.app.view',
  marketplace = 'use.marketplace.connect',
  themeStore = 'manage.store.front.customization',
  themeAdded = 'manage.store.front.customization',
  settingsInventoryAddresses = 'inventory.locations.view',
  settingsInventoryCreation = 'manage.store.inventory.addresses',
  settingsInventoryAddress = 'manage.store.inventory.addresses',
  settingsInventoryAddressesV2 = 'inventory.locations.view',
  settingsInventoryAddressesV2Priority = 'inventory.locations.withdrawal.priority.manage',
  settingsInventoryAddressesV2Stocks = 'inventory.locations.stock.management.view',
  settingsInventoryCreationV2 = 'manage.store.inventory.addresses',
  settingsInventoryAddressV2 = 'manage.store.inventory.addresses',
  settingsInventoryTransferRequests = 'stock.transfer.list',
  settingsInventoryTransferRequestDetails = 'stock.transfer.list',
  settingsInventoryTransferRequestModification = 'stock.transfer.modify',
  settingsInventoryTransferRequestCreation = 'stock.transfer.request',
  settingsInventoryTransferRequestReceive = 'stock.transfer.receive',
  settingsInventoryTransferRequestSend = 'stock.transfer.send',
  settingsInventoryTransferRequestApproval = 'stock.transfer.approval',
  affiliateList = 'affiliate.marketing.manage',
  loyaltyProgram = 'loyalty.view',
  loyaltyProgramManage = 'loyalty.manage',
  qitafProgram = 'view.qitaf.home',
  qitafActivation = 'view.qitaf.home',
  affiliateCreation = 'affiliate.marketing.manage',
  affiliateDetails = 'affiliate.marketing.manage',
  shippingOptions = 'shipping.and.delivery.view',
  shippingRequirments = 'shipping.and.delivery.manage',
  shippingActivation = 'shipping.and.delivery.manage',
  shippingDetails = 'shipping.and.delivery.manage',
  shippingDetailsUpdate = 'shipping.and.delivery.manage',
  settingsOrdersProductsSettings = 'orders.and.products.specifications.view',
  storeDesign = 'theme.market.store.identity.manage',
  ticktes = 'manage.store.front.ticktes',
  zidpos = 'pos.view',
  zidposOnboarding = 'pos.view',
  zidposReports = 'pos.view&pos.reports.view',
  zidposPaymentMethods = 'pos.view&pos.payment.methods.view',
  zidposManagePaymentMethods = 'pos.view&pos.payment.methods.manage',
  zidposCashiers = 'pos.view&pos.cashier.view',
  zidposNewCashier = 'pos.view&pos.subscribe',
  zidposManageCashier = 'pos.cashier.manage',
  zidposSubscribe = 'pos.view&pos.subscribe',
  zidposPreSubscription = 'pos.view',
  zidposExports = 'pos.export',
  themeMarketManageThemeSDK = 'theme.market.custom.theme.sdk.manage',
  themeMarketPurchaseTheme = 'theme.market.themes.purchase',
  themeMarketCustomizeTheme = 'theme.market.custom.theme.sdk.manage',
  themeMarketManageStoreIdentity = 'theme.market.store.identity.manage',
  smsCampaignsPricing = 'sms.campaigns.manage',
  smsCampaigns = 'sms.campaigns.manage',
  createSmsCampaign = 'sms.campaigns.manage',
  editSmsCampaign = 'sms.campaigns.manage',
  viewSmsCampaign = 'sms.campaigns.manage',
  giftOptions = 'gift.page.options.view',
  langCurrency = 'language.and.currencies.view',
  abandonedCartsMessages = 'abandoned.carts.automatic.messages.view',
  ticketing = 'ticketing.support.manage',
  createTicket = 'ticketing.support.manage',
  ticketDetails = 'ticketing.support.manage',
  promotionalMessages = 'automatic.messages.view',
  checkoutOptions = 'checkout.page.options.view',
  notifyRestockedProduct = 'notify.me.when.product.is.back.in.stock.view',
  notifyRestockedProductCreateMessage = 'notify.me.when.product.is.back.in.stock.manage',
  constraintsPaymentAndShipping = 'payment.and.shipping.constraints.view',
  abandonedCartsCreateMessage = 'abandoned.carts.automatic.messages.manage',
  filtrationAttribute = 'products.manage.filtration.settings',
  questionsRatings = 'questions.and.ratings.view',
  ratingsNotifications = 'questions.and.ratings.manage',
  questionsNotifications = 'questions.and.ratings.manage',
  ratingsQuestionsMessages = 'questions.and.ratings.manage',
  signOutFromAllDevices = 'manage.store.account.settings.signout',
  zidWallet = 'wallet.view',
  zidWalletManage = 'wallet.view&wallet.manage',
  zidWalletExport = 'wallet.view&wallet.export',
  metafields = 'metafields.view',
  addMetafield = 'metafields.manage',
  editMetafield = 'metafields.manage',
  addExtraPage = 'theme.market.store.identity.manage',
  editExtraPage = 'theme.market.store.identity.manage',
  groupedProduct = 'products.create',
  editGroupedProduct = 'products.view',
  productVariant = 'products.view',
  productsRestore = 'products.trash.bin',
  settingsInventoryAddressV2Stocktaking = 'stocktaking.manage',
  settingsInventoryAddressV2CreateStocktaking = 'stocktaking.manage',
  settingsInventoryAddressV2CreateStocktakingCount = 'stocktaking.manage',
  settingsInventoryAddressV2StocktakingOverview = 'stocktaking.manage',
  viewAnalyticsFinancial = 'view.analytics.financial',
  viewAnalyticsMarketing = 'view.analytics.marketing',
  viewAnalyticsOperations = 'view.analytics.operations',
  viewAnalyticsProducts = 'view.analytics.products ',
  viewAnalyticsZidpay = 'view.analytics.zidpay ',
  userManagement = 'users.um.view',
  permissionGroups = 'users.um.view',
  createUser = 'users.um.view&users.manage',
  createPermissionsGroup = 'users.um.view&users.manage',
  editPermissionsGroup = 'users.um.view&users.permissions.edit',
  editUser = 'users.um.view&users.permissions.edit',
  viewSalesReports = 'view.reports.marketing.reports',
  createConstraint = 'payment.and.shipping.constraints.manage',
  editConstraint = 'payment.and.shipping.constraints.manage',
  MerchantGrowth = 'zidgrowth.view',
  MerchantGrowthRegistration = 'zidgrowth.view',
  StoreSaudiVatInfoForm = 'vat.settings.manage',
}
