export const settingsApiUrls = {
  cities: '/api/v1/cities',

  // use those urls for Older inventory-addresses
  inventoryAddresses: '/api/v1/inventory-addresses',
  inventoryAddress: '/api/v1/inventory-addresses/{addressId}',
  updateInventoryAddress: '/api/v1/inventory-addresses/{addressId}/update',
  deleteInventoryAddress: '/api/v1/inventory-addresses/{addressId}/delete',

  // use those urls for Newer inventory-addresses
  inventoryAddressesV2: '/api/v1/locations',
  inventoryAddressV2: '/api/v1/locations/{locationId}',
  updateInventoryAddressV2: '/api/v1/locations/{locationId}',
  //shipping zons in inventory addresses component
  shippingZonesOptions: '/api/v1/shipping-methods',
  shippingZoneLocations: '/api/v1/locations/{locationId}/shipping-zones',
  deleteLocationsTier: '/api/v1/locations/{locationId}/shipping-zones/{tierId}',

  updateStocks: '/api/v1/locations/{location_id}/stock-update',
  exportStocks: '/api/v1/products/export-stocks',
  importStocks: '/api/v1/products/import-stocks',
  importStocksJob: '/job?slug=import_stocks',

  inventoryTransferRequests: '/api/v1/stocks-transfers',
  inventoryTransferRequest: '/api/v1/stocks-transfers/{requestId}',
  inventoryTransferRequestChangeStatus: '/api/v1/stocks-transfers/{requestId}/change-status',

  currencies: '/api/v1/currencies',
  countries: '/api/v1/countries',
  storeSettingsInfo: '/api/v1/account/settings/store/get-store-settings-info',
  updateStoreSettingsInfo: '/api/v1/account/settings/store/update-store-settings-info',
  updateStoreBusinessPlace: '/api/v1/account/settings/store/update-business-place',
  locationBusinessPlace: '/api/v1/account/settings/store/location-business-place',
  updateStoreNameOnce: '/api/v1/account/settings/store/update-store-name',
  updateCategory: '/api/v1/account/settings/store/update-category',
  updateBusinessType: '/api/v1/account/settings/store/update-business-type',
  completeSetup: '/api/v1/account/settings/store/complete-setup',
  getStoreBusinessPlace: '/api/v1/account/settings/store/get-business-place',
  updateAboutStoreInfo: '/api/v1/account/settings/store/about',
  getFeatureFlagsInfo: '/api/v1/managers/store/feature-flags',
  deleteCommercialRegisterCertificate: '/api/v1/account/settings/delete-store-commercial-register-certificate',
  deleteMaroofCertificate: '/api/v1/account/settings/delete-store-maroof-certificate',
  deleteFreelanceCertificate: '/api/v1/account/settings/delete-store-freelance-certificate',
  deleteCivilIdImage: '/api/v1/account/settings/delete-store-civil-id-image',
  getCitiesByCountryID: '/api/v1/countries/{countryId}/cities',
  productsSettings: '/api/v1/settings/orders-products-settings',
  checkoutSettings: '/api/v1/settings/checkout-settings',
  uploadCardTemplates: '/api/v1/settings/gift-card-upload',
  giftingSettings: '/api/v1/settings/gifting-settings',
  getFilteringProductsByCitySettings: '/api/v1/settings/filtering-products-by-city-Settings',
  filteringProductsByCitySettings: '/api/v1/settings/filtering-products-by-city-Settings',
  updateAccountSettings: '/api/v1/account/settings/account-info',
  updateAccountPassword: '/api/v1/account/settings/account-password',
  getBusniessTypeFieldsForKYC: '/api/v1/account/settings/store/busniess-type-fields-for-KYC',
  updateStoreBusniessDetails: '/api/v1/account/settings/store/busniess-type-fields-for-KYC',
  customersEmailVerificationStatus: '/api/v1/account/settings/store/customers-email-status',
  hasViewedDomainsPageStatus: '/api/v1/account/settings/store/has-viewed-domains-page',
  customersLoginSMSStatus: '/api/v1/account/settings/store/customers-login-sms-status',
  customersLoginEmailStatus: '/api/v1/account/settings/store/customers-login-email-status',
  customersLoginWhatsappStatus: '/api/v1/account/settings/store/customers-login-by-whatsapp-status',
  gmapsMethodsVerifications: '/api/v1/account/settings/store/{action_key}',
  senderEmailInvoice: '/api/v1/account/settings/store/sender-email',
  storeSettings: '/api/v1/account/settings/store-settings',
  applePayAllBrowsersStatus: '/api/v1/account/settings/checkout-apple-pay-status',
  applePayQuickCheckoutStatus: '/api/v1/account/settings/apple-pay-quick-checkout',
  singlePageCheckoutStatus: '/api/v1/account/settings/checkout-single-page-checkout-status',
  twoFactorAuthenticationStatus: '/api/v1/account/settings/toggle-2FA-status',
  buyAsGuestStatus: '/api/v1/managers/store/settings/{action_key}',
  multiDiscountsStatus: '/api/v1/account/settings/store/multi-discounts-status',
  cartItemsAvailabilityStatus: '/api/v1/account/settings/checkout-cart-items-availability-status',
  updateThemeColors: '/api/v1/themes/colors',
  getThemeColors: '/api/v1/themes/colors',
  storeLogo: '/api/v1/store/logo',
  storeEnglishLogo: '/api/v1/store/logo/english',
  storeCoverImage: '/api/v1/store/cover-image',
  storeFavicon: '/api/v1/store/favicon',
  storeCustomCopyrights: '/api/v1/store/custom-copyrights',
  themes: '/api/v1/themes',
  themesCss: '/api/v1/themes/css',
  activateTheme: '/api/v1/themes/{themeId}/activate',
  deactivateTheme: '/api/v1/themes/{themeId}/deactivate',
  updateTheme: '/api/v1/themes/{themeId}/update',
  extraPages: '/api/v1/extra-pages',
  extraPagesActivation: '/api/v1/extra-pages/activation',
  extraPage: '/api/v1/extra-pages/{pageId}',
  publishCustomTheme: '/api/v1/themes/{themeId}/publish-custom-theme',
  cancelPublishCustomTheme: '/api/v1/themes/{publicThemeId}/publish-custom-theme',
  getStoreFrontMenuList: '/api/v1/storefront-menus',
  updateStoreFrontMenuType: '/api/v1/storefront-menus/{menuId}/type',
  createStoreFrontMenuItem: '/api/v1/storefront-menus/create',
  updateStoreFrontMenuList: '/api/v1/storefront-menus/update',
  deleteStoreFrontMenuList: '/api/v1/storefront-menus/{menuId}/delete',
  accountCurrencies: '/api/v1/account/store/currencies',
  currenciesDefault: '/api/v1/account/settings/currencies/default',
  disableCurrency: '/api/v1/account/settings/currencies/{currencyId}/delete',
  enableCurrency: '/api/v1/account/settings/currencies',
  switchLanguage: '/api/v1/account/store/languages',
  defaultLanguage: '/account/store/default-languages',
  moderatorSettings: '/moderators/{id}?response_json=true',
  getVatSettings: '/api/v1/account/settings/vat/settings',
  domains: '/api/v1/managers/store/domains',
  domainRecords: '/api/v1/managers/store/domains/{domainId}/records',
  deleteDomainRecord: '/api/v1/managers/store/domains/{domainId}/records/{recordId}',
  createNewRecord: '/api/v1/managers/store/domains/{domainId}/records',
  activateDomain: '/api/v1/managers/store/domains/{domainId}/activate',
  deleteDomain: '/api/v1/managers/store/domains/{domainId}/delete',
  createDomain: '/api/v1/managers/store/domains',
  getDomainDetails: '/api/v1/managers/store/domains/{domainId}/details',
  verifyDomain: '/api/v1/managers/store/domains/{domainId}/verify',
  orderNotifications: '/api/v1/orders/notifications/data',
  completeStoreInfo: '/api/v1/account/settings/required-info',
  vatCountry: '/api/v1/account/settings/vat/country',
  customerNote: '/api/v1/account/settings/customer-note',
  signoutFromAllDevices: '/api/v1/account/settings/logout-all-users',
  accountSettings: '/api/v1/account/settings',
  updateOtpMethod: '/api/v1/account/settings/otp-method',
  updateSessionLogoutStrictness: '/api/v1/account/settings/session-logout-strictness',
  storeVerifySbc: '/api/v1/account/settings/store/verify-sbc',
  notifyRestockProductsStatistics: '/api/v1/settings/notify-statistics',
  notifyRestockProductsNotifications: '/api/v1/settings/notify-customer',
  notifyRestockProductsSaveAvailabilityNotificationsSettings: '/api/v1/settings/availability-notifications',
  notifyRestockProductsSendAvailabilityNotificationEmail: '/api/v1/settings/send-availability-notification-email',
  notifyRestockProductsExport: '/api/v1/settings/notify-restock-products-export',
  addExtraPage: '/api/v1/add-extra-page',
  viewExtraPage: '/api/v1/view-extra-page/{id}',
  updateExtraPage: '/api/v1/extra-page/{id}/update',
  orderListView: '/api/v1/managers/store/order-list-view-settings',
  stocktakingCreateCounting: '/api/v1/stocktaking-create-counting',
  getStocktaking: '/api/v1/stocktaking/{countId}',
  getStocktakingsList: '/api/v1/stocktakings',
  stocktakingItems: '/api/v1/stocktaking/{countId}/items',
  stocktakingNextItems: '/api/v1/stocktaking/{countId}/items/{cursorId}',
  stocktakingUpdateCountItem: '/api/v1/stocktaking/{countId}/count',
  stocktakingUpdateCountStatus: '/api/v1/stocktaking/{countId}/status',
  updateStocktaking: '/api/v1/stocktaking/update/{countId}',
  exportStocktakingProducts: '/api/v1/stocktaking/{countId}/export',
  importStocktakingProducts: '/api/v1/stocktaking/{countId}/import',
  savePresetupQuestions: '/api/v1/save-presetup-questions',
};
