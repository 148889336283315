import { FinanceEntryPointComponent } from './../Finance/views/FinanceEntryPoint/FinanceEntryPoint';
import { FinanceLandingComponent } from './../Finance/views/FinanceLanding/FinanceLanding';
import { Route, RouteConfig } from 'vue-router';
import { RoutingRouteEnum } from './route.enum';
import appI18nFactory from '../app/i18n/factory';
import { DetailsPageViewTypeEnum } from '../zidpay/types/DetailsPageViewType.enum';
import isCharitableDonationStore from '../common/helpers/user/is-charitable-donation-store';
import { FinanceMarketingComponent } from '../Finance/views/FinanceMarketing/FinanceMarketing';
import { FinanceActivationComponent } from '../Finance/views/FinanceActivation/FinanceActivation';
import { zidshipActiveGuard } from '../zidship/guards/zidship-guard';
import { inventoryAddressesGuard } from './guards/inventory-addresses-guard';
import { marketplaceConnectGuard } from './guards/marketplace-connect.guard';
import hasActivatedPOS from '../common/helpers/user/has-pos';
import helpersTriggerGTMEventAction from '../common/helpers/trigger-gtm-event/trigger-gtm-event';
import { CommonHelpersTriggerGTMEventsNamesEnum } from '../common/helpers/trigger-gtm-event/events-names.enums';
import { CommonHelpersTriggerGtmEventsLocationsEnum } from '../common/helpers/trigger-gtm-event/events-locations.enums';
import isPosReady from '../common/helpers/user/is-pos-ready';
import { pilotsGuard } from './guards/pilots-guard';
import { isPackageAvailableToCheckout } from './guards/is-package-available-to-checkout.guard';
import { subscriptionWebManagerGuard } from './guards/subscription-web-manager.guard';
import { hasGroupedProducts } from './guards/grouped-products.guard';
import isFeatureAvailable, { isOldFeatureAvailable } from '../common/helpers/is-feature-available';

const i18n = appI18nFactory();

export const routingRoutes: Array<RouteConfig> = [
  {
    path: '/home',
    name: RoutingRouteEnum.home,
    meta: {
      title: i18n.messages[i18n.locale]['common.page_header.main'],
    },
    component: () =>
      import(/* webpackChunkName: "home" */ '../home/views/Home/Home').then((module) => module.HomeViewsHomeComponent),
  },
  {
    path: '/zidwallet',
    name: RoutingRouteEnum.zidWallet,
    meta: {
      title: i18n.messages[i18n.locale]['wallet.details.title'],
    },
    component: () =>
      import(/* webpackChunkName: "zidWallet" */ '../zidwallet/views/Wallet/Wallet').then(
        (module) => module.WalletViewsWalletComponent,
      ),
  },
  {
    path: '/login',
    name: RoutingRouteEnum.login,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.login.title'],
    },
    component: () =>
      import(/* webpackChunkName: "login" */ '../account/views/Login/Login').then(
        (module) => module.LoginViewsLoginComponent,
      ),
  },
  {
    path: '/register',
    name: RoutingRouteEnum.register,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.register.title'],
    },
    component: () =>
      import(/* webpackChunkName: "register" */ '../account/views/Register/Register').then(
        (module) => module.RegisterViewsRegisterComponent,
      ),
  },
  {
    path: '/phone-number',
    name: RoutingRouteEnum.phoneNumber,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.register.title'],
    },
    component: () =>
      import(/* webpackChunkName: "PhoneNumber" */ '../account/views/Register/PhoneNumber.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/register/:campaign',
    name: RoutingRouteEnum.register,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.register.title'],
    },
    component: () =>
      import(/* webpackChunkName: "register" */ '../account/views/Register/Register').then(
        (module) => module.RegisterViewsRegisterComponent,
      ),
  },
  {
    path: '/personal-info',
    name: RoutingRouteEnum.registerPersonalInfo,
    meta: {
      title: i18n.messages[i18n.locale]['account.register.heading'],
    },
    component: () =>
      import(/* webpackChunkName: "registerPersonalInfo" */ '../account/views/Register/PersonalInfo').then(
        (module) => module.RegisterPersonalInfoComponent,
      ),
  },
  {
    path: '/login/other',
    name: RoutingRouteEnum.loginBtns,
    meta: {
      title: '',
    },
    component: () =>
      import(/* webpackChunkName: "loginBtns" */ '../account/views/Login/LoginBtns').then(
        (module) => module.LoginBtnsViewsLoginBtnsComponent,
      ),
  },
  {
    path: '/login/mobile',
    name: RoutingRouteEnum.loginMobile,
    meta: {
      title: '',
    },
    component: () =>
      import(/* webpackChunkName: "loginMobile" */ '../account/views/Login/LoginMobile').then(
        (module) => module.LoginMobileViewsLoginMobileComponent,
      ),
  },

  {
    path: '/reset',
    name: RoutingRouteEnum.resetPass,
    meta: {
      title: '',
    },
    component: () =>
      import(/* webpackChunkName: "resetPass" */ '../account/views/Password/ResetPassword').then(
        (module) => module.ResetPasswordViewsResetPassComponent,
      ),
  },
  {
    path: '/otp',
    name: RoutingRouteEnum.otp,
    meta: {
      title: '',
    },
    component: () =>
      import(/* webpackChunkName: "otp" */ '../account/views/Otp/Otp').then((module) => module.OtpViewsOTPComponent),
  },
  {
    path: '/login/password',
    name: RoutingRouteEnum.loginWithPassword,
    meta: {
      title: '',
    },
    component: () =>
      import(/* webpackChunkName: "loginWithPassword" */ '../account/views/Password/Password').then(
        (module) => module.PasswordViewsPasswordComponent,
      ),
  },
  {
    path: '/change-mobile',
    name: RoutingRouteEnum.changeMobile,
    meta: {
      title: '',
    },
    component: () =>
      import(/* webpackChunkName: "changeMobile" */ '../account/views/ChangeMobile').then(
        (module) => module.ChangeMobileViewsMobileComponent,
      ),
  },
  {
    path: '/setup',
    name: RoutingRouteEnum.registerWizard,
    meta: {
      title: '',
    },
    component: () =>
      import(/* webpackChunkName: "registerWizard" */ '../account/views/Register/RegisterWizard').then(
        (module) => module.RegisterWizardViewsRegisterWizardComponent,
      ),
  },
  {
    path: '/presetup',
    name: RoutingRouteEnum.presetup,
    meta: {
      title: '',
    },
    component: () =>
      import(/* webpackChunkName: "presetup" */ '../account/views/Register/Presetup.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/thank-you',
    name: RoutingRouteEnum.thankYou,
    meta: {
      title: '',
    },
    component: () =>
      import(/* webpackChunkName: "thankYouPage" */ '../account/views/Register/ThankYou/ThankYou').then(
        (module) => module.RegisterViewsThankYouComponent,
      ),
  },
  {
    path: '/password/reset/:token/:email',
    name: RoutingRouteEnum.newPassword,
    meta: {
      title: '',
    },
    component: () =>
      import(/* webpackChunkName: "newPassword" */ '../account/views/NewPassword/NewPassword').then(
        (module) => module.NewPasswordViewsNewPasswordComponent,
      ),
  },
  {
    path: '/orders',
    name: RoutingRouteEnum.orders,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.orders.title'],
    },
    component: () =>
      import(/* webpackChunkName: "orders" */ '../orders/views/Orders/Orders').then(
        (module) => module.OrdersViewsOrdersComponent,
      ),
  },
  {
    path: '/orders/drafts',
    name: RoutingRouteEnum.orderDrafts,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.orders.drafts'],
    },
    component: () =>
      import(/* webpackChunkName: "ordersDrafts" */ '../orders/views/OrderDrafts/OrderDrafts').then(
        (module) => module.OrdersViewsOrderDraftsComponent,
      ),
  },
  {
    path: '/orders/create',
    name: RoutingRouteEnum.orderCreation,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.orders.create'],
    },
  },
  {
    path: '/orders/create/:draftSessionId',
    name: RoutingRouteEnum.orderDraft,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.orders.create'],
    },
    component: () =>
      import(/* webpackChunkName: "orderCreation" */ '../orders/views/OrderCreation/OrderCreation.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/orders/notifications',
    name: RoutingRouteEnum.orderNotifications,
    meta: {
      title:
        i18n.messages[i18n.locale][
          isCharitableDonationStore()
            ? 'order_notification.donations.title'
            : 'sidebar.navigation_section.orders.notifications'
        ],
    },
    component: () =>
      import(
        /* webpackChunkName: "orderNotifications" */ '../orders/views/OrderNotifications/OrderNotifications.vue'
      ).then((module) => module.default),
  },
  {
    path: '/orders/notifications/pricing',
    name: RoutingRouteEnum.orderNotificationsPricing,
    component: () =>
      import(
        /* webpackChunkName: "orderNotificationsPricing" */ '../orders/views/OrderNotificationsPricing/OrderNotificationsPricing'
      ).then((module) => module.OrdersViewsOrderNotificationsPricing),
  },
  {
    path: '/orders/invoices',
    name: RoutingRouteEnum.ordersInvoices,
    meta: {
      title: i18n.messages[i18n.locale]['orders.invoice.title'],
    },
    beforeEnter(to: Route, _: Route, next: Function): void {
      if (!to.query['order_id']) next({ name: RoutingRouteEnum.orders });
      else next();
    },
    component: () =>
      import(/* webpackChunkName: "ordersInvoices" */ '../orders/views/Invoices/Invoices').then(
        (module) => module.OrdersViewsInvoicesComponent,
      ),
  },
  {
    path: '/orders/:orderId',
    name: RoutingRouteEnum.orderDetails,
    meta: {
      title: i18n.messages[i18n.locale]['order_details.header.title'],
    },
    component: () =>
      import(/* webpackChunkName: "orderDetails" */ '../orders/views/OrderDetails/OrderDetails').then(
        (module) => module.OrdersViewsOrderDetailsComponent,
      ),
  },
  {
    path: '/reverse-order/:orderId',
    name: RoutingRouteEnum.reverseOrder,
    meta: {
      title: i18n.messages[i18n.locale]['zidship.return'],
    },
    component: () =>
      import(/* webpackChunkName: "returnOrder" */ '../orders/views/ReverseOrder/ReverseOrder').then(
        (module) => module.OrdersViewsReturnOrderComponent,
      ),
  },
  {
    path: '/products',
    name: RoutingRouteEnum.products,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.products.title'],
    },
    component: () =>
      import(/* webpackChunkName: "products" */ '../products/views/Products/Products').then(
        (module) => module.ProductsViewsProductsComponent,
      ),
  },
  {
    path: '/products/bulk-edit',
    name: RoutingRouteEnum.bulkEdit,
    meta: {
      title: i18n.messages[i18n.locale]['products.bulk_edit.title'],
    },
    component: () =>
      import(/* webpackChunkName: "products" */ '../products/views/BulkEdit/BulkEdit').then(
        (module) => module.ProductsViewsBulkEditComponent,
      ),
  },
  {
    path: '/categories',
    name: RoutingRouteEnum.categories,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.products.categories'],
    },
    component: () =>
      import(/* webpackChunkName: "categories" */ '../categories/views/Categories/Categories').then(
        (module) => module.CategoriesViewsCategoriesComponent,
      ),
  },
  {
    path: '/categories/:categoryId',
    name: RoutingRouteEnum.category,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.products.categories'],
    },
    component: () =>
      import(/* webpackChunkName: "categories" */ '../categories/views/Categories/Categories').then(
        (module) => module.CategoriesViewsCategoriesComponent,
      ),
  },
  {
    path: '/account/settings/delivery-options/zidship',
    name: RoutingRouteEnum.zidShip,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zid_ecosystem.shipping_and_delivery'],
    },
    component: () =>
      import(/* webpackChunkName: "ZidShipViewsMainLayout" */ '../zidship/views/MainLayout/MainLayout').then(
        (module) => module.ZidShipViewsMainLayoutComponent,
      ),
    children: [
      {
        path: '/account/settings/delivery-options/zidship',
        name: RoutingRouteEnum.zidShip,
        meta: {
          title: i18n.messages[i18n.locale]['sidebar.navigation_section.zid_ecosystem.shipping_and_delivery'],
        },
        component: () =>
          import(/* webpackChunkName: "zidShip" */ '../zidship/views/index/index').then(
            (module) => module.ZidShipViewsIndexPageComponent,
          ),
      },
      {
        path: '/account/settings/delivery-options/zidship/pricing-zone/add/:code',
        name: RoutingRouteEnum.zidshipAddPricingZone,
        meta: {
          title: i18n.messages[i18n.locale]['sidebar.navigation_section.zid_ecosystem.shipping_and_delivery'],
        },
        component: () =>
          import(/* webpackChunkName: "AddPricingZone" */ '../zidship/views/PricingZone/AddPricingZone.vue').then(
            (module) => module.default,
          ),
        beforeEnter: (to: Route, from: Route, next: Function): void => {
          zidshipActiveGuard(to, from, next, i18n.messages[i18n.locale]['zidship.header.warning_access_message']);
          next();
        },
      },
      {
        path: '/account/settings/delivery-options/zidship/pricing-zone/edit/:code',
        name: RoutingRouteEnum.zidshipEditPricingZone,
        meta: {
          title: i18n.messages[i18n.locale]['sidebar.navigation_section.zid_ecosystem.shipping_and_delivery'],
        },
        component: () =>
          import(/* webpackChunkName: "EditPricingZone" */ '../zidship/views/PricingZone/EditPricingZone.vue').then(
            (module) => module.default,
          ),
        beforeEnter: (to: Route, from: Route, next: Function): void => {
          zidshipActiveGuard(to, from, next, i18n.messages[i18n.locale]['zidship.header.warning_access_message']);
          next();
        },
      },
      {
        path: '/account/settings/delivery-options/zidship/service-level-details/:code',
        name: RoutingRouteEnum.zidshipServiceLevelDetails,
        meta: {
          title: i18n.messages[i18n.locale]['sidebar.navigation_section.zid_ecosystem.shipping_and_delivery'],
        },
        component: () =>
          import(
            /* webpackChunkName: "ServiceLevelDetails" */ '../zidship/views/ServiceLevelDetails/ServiceLevelDetails'
          ).then((module) => module.ZidShipServiceLevelDetailsPageComponent),
      },
      {
        path: '/account/settings/delivery-options/zidship/account-settings',
        name: RoutingRouteEnum.zidshipAccountSettings,
        meta: {
          title: i18n.messages[i18n.locale]['zidship.main_layout.routes.zidship_account'],
        },
        component: () =>
          import(/* webpackChunkName: "AccountSettings" */ '../zidship/views/AccountSettings/AccountSettings').then(
            (module) => module.ZidShipViewsAccountSettingsComponent,
          ),
        beforeEnter: (to: Route, from: Route, next: Function): Promise<void> =>
          zidshipActiveGuard(
            to,
            from,
            next,
            i18n.messages[i18n.locale]['zidship.header.warning_account_settings_access_message'],
          ),
      },
      {
        path: '/account/settings/delivery-options/zidship/help-center',
        name: RoutingRouteEnum.zidshipHelpCenter,
        meta: {
          title: i18n.messages[i18n.locale]['statusbar.help_center'],
        },
        component: () =>
          import(/* webpackChunkName: "ZidShipViewsHelpCenter" */ '../zidship/views/HelpCenter/HelpCenter').then(
            (module) => module.ZidShipViewsHelpCenterComponent,
          ),
      },
      {
        path: '/account/settings/delivery-options/zidship/waybills-packages',
        name: RoutingRouteEnum.zidshipWaybillsPackages,
        meta: {
          title: i18n.messages[i18n.locale]['zidship.main_layout.routes.waybills'],
        },
        component: () =>
          import(
            /* webpackChunkName: "zidshipWaybillsPackages" */ '../zidship/views/WaybillsPackages/WaybillsPackages'
          ).then((module) => module.ZidShipViewswaybillsPackagesPageComponent),

        beforeEnter: (to: Route, from: Route, next: Function): Promise<void> =>
          zidshipActiveGuard(
            to,
            from,
            next,
            i18n.messages[i18n.locale]['zidship.header.warning_account_settings_access_message'],
          ),
      },
      {
        path: '/account/settings/delivery-options/zidship/transactions',
        name: RoutingRouteEnum.zidshipWallet,
        meta: {
          title: i18n.messages[i18n.locale]['zidship.wallet.header'],
        },
        component: () =>
          import(/* webpackChunkName: "ZidShipViewsWalletPage" */ '../zidship/views/Wallet/Wallet').then(
            (module) => module.ZidShipViewsWalletPageComponent,
          ),
        beforeEnter: (to: Route, from: Route, next: Function): Promise<void> =>
          zidshipActiveGuard(
            to,
            from,
            next,
            i18n.messages[i18n.locale]['zidship.header.warning_account_settings_access_message'],
          ),
      },
      {
        path: '/account/settings/delivery-options/zidship/reports',
        name: RoutingRouteEnum.zidshipReports,
        meta: {
          title: i18n.messages[i18n.locale]['common.reports'],
        },
        component: () =>
          import(/* webpackChunkName: "ZidShipViewsReportsPage" */ '../zidship/views/Reports/Reports').then(
            (module) => module.ZidShipViewsReportsPageComponent,
          ),

        beforeEnter: (to: Route, from: Route, next: Function): Promise<void> =>
          zidshipActiveGuard(
            to,
            from,
            next,
            i18n.messages[i18n.locale]['zidship.header.warning_account_settings_access_message'],
          ),
      },
      {
        path: '/account/settings/delivery-options/zidship/shipments',
        name: RoutingRouteEnum.zidshipShipments,
        meta: {
          title: i18n.messages[i18n.locale]['zidship.main_layout.routes.shipments'],
        },
        component: () =>
          import(/* webpackChunkName: "ZidShipViewsShipments" */ '../zidship/views/Shipments/Shipments').then(
            (module) => module.ZidShipViewsShipmentsComponent,
          ),
      },
    ],
  },
  {
    path: '/account/settings/shipping-options',
    name: RoutingRouteEnum.shippingOptions,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zid_ecosystem.shipping_and_delivery'],
    },
    component: () =>
      import(/* webpackChunkName: "shippingOptions" */ '../shipping/views/ShippingOptions').then(
        (module) => module.ShippingViewsDeliveryOptionsComponent,
      ),
  },
  {
    path: '/account/settings/shipping-requirements/:id',
    name: RoutingRouteEnum.shippingRequirments,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zid_ecosystem.shipping_and_delivery'],
    },
    component: () =>
      import(/* webpackChunkName: "shippingRequirments" */ '../shipping/views/ShippingRequirements').then(
        (module) => module.ShippingViewsShippingRequirements,
      ),
  },
  {
    path: '/account/settings/shipping-activation/:id',
    name: RoutingRouteEnum.shippingActivation,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zid_ecosystem.shipping_and_delivery'],
    },
    component: () =>
      import(/* webpackChunkName: "shippingActivation" */ '../shipping/views/ShippingActivation').then(
        (module) => module.ShippingViewsShippingActivationComponent,
      ),
  },
  {
    path: '/account/settings/shipping-details/:id',
    name: RoutingRouteEnum.shippingDetails,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zid_ecosystem.shipping_and_delivery'],
    },
    component: () =>
      import(/* webpackChunkName: "shippingDetails" */ '../shipping/views/ShippingDetails').then(
        (module) => module.ShippingViewsShippingDetails,
      ),
  },
  {
    path: '/account/settings/shipping-details/update/:id',
    name: RoutingRouteEnum.shippingDetailsUpdate,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zid_ecosystem.shipping_and_delivery'],
    },
    component: () =>
      import(/* webpackChunkName: "shippingDetails" */ '../shipping/views/ShippingDetailsUpdate').then(
        (module) => module.ShippingViewsShippingDetailsUpdate,
      ),
  },
  {
    path: '/account/settings/payment-options-old',
    name: 'paymentOptionsOld',
    meta: {
      title: i18n.messages[i18n.locale]['settings.payment'],
    },
    component: () =>
      import('../payment-options/views/PaymentOptions/PaymentOptions').then(
        (module) => module.PaymentOptionsViewsPaymentOptionsComponent,
      ),
  },
  {
    path: '/account/settings/payment-options',
    name: RoutingRouteEnum.paymentOptions,
    meta: {
      title: i18n.messages[i18n.locale]['settings.payment'],
    },
    component: () =>
      import(
        /* webpackChunkName: "paymentOptions" */ '../payment-options-new/views/PaymentOptions/PaymentOptionsView.vue'
      ).then((module) => module.default),
  },
  {
    path: '/account/settings/payment-options/other',
    name: RoutingRouteEnum.otherPaymentOptions,
    meta: {
      title: i18n.messages[i18n.locale]['payment_options.other.title'],
    },
    component: () =>
      import(
        /* webpackChunkName: "paymentOptions" */ '../payment-options/views/OtherPaymentOptions/OtherPaymentOptions'
      ).then((module) => module.PaymentOptionsViewsOtherPaymentOptionsComponent),
  },
  {
    path: '/account/settings/payment-options/other/:id',
    name: RoutingRouteEnum.thirdPartyProviderDetails,
    meta: {
      title: i18n.messages[i18n.locale]['payment_options.other.title'],
    },
    component: () =>
      import(
        /* webpackChunkName: "thirdPartyProviderDetails" */ '../payment-options/views/ThirdPartyProviderDetails/ThirdPartyProviderDetails'
      ).then((module) => module.PaymentOptionsViewsThirdPartyProviderDetailsComponent),
  },
  {
    path: '/account/settings/domains/:domainId',
    name: RoutingRouteEnum.settingsCurrentDomain,
    meta: {
      title: i18n.messages[i18n.locale]['settings.account_settings.domain_management.title'],
    },
    component: () =>
      import(
        /* webpackChunkName: "settingsCurrentDomain" */ '../settings/views/Domains/CurrentDomain/CurrentDomain'
      ).then((module) => module.DomainsViewsSettingsCurrenctDomain),
  },
  {
    path: '/account/settings/link-existing-domain/:domainId?',
    name: RoutingRouteEnum.settingsLinkExistingDomain,
    component: () =>
      import(
        /* webpackChunkName: "settingsLinkExistingDomain" */ '../settings/views/Domains/LinkExistingDomain/LinkExistingDomain'
      ).then((module) => module.DomainsViewsSettingsLinkExistingDomain),
  },
  {
    path: '/account/settings/domains',
    name: RoutingRouteEnum.domains,
    meta: {
      title: i18n.messages[i18n.locale]['settings.account_settings.domains_list.title'],
    },
    component: () =>
      import(/* webpackChunkName: "paymentOptions" */ '../settings/views/Domains/DomainsList/DomainsList').then(
        (module) => module.DomainsViewsSettingsDomainsList,
      ),
  },
  {
    path: '/account/settings/language-currency',
    name: RoutingRouteEnum.langCurrency,
    meta: {
      title: i18n.messages[i18n.locale]['settings.language_currency.page_title'],
    },
    component: () =>
      import(/* webpackChunkName: "languageCurrency" */ '../settings/views/LanguageCurrency/LanguageCurrency').then(
        (module) => module.LanguageCurrencyViewsLanguageOptionsComponent,
      ),
  },
  {
    path: '/bundles/create',
    name: RoutingRouteEnum.bundleOfferCreation,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.marketing.bundle_offers'],
    },
    component: () =>
      isFeatureAvailable('product_bundles.creating_bundles')
        ? import(/* webpackChunkName: "bundleOfferCreation" */ '../bundles/views/BundleCreation/BundleCreation').then(
            (module) => module.BundlesViewsBundleCreationComponent,
          )
        : import(/* webpackChunkName: "UpgradePackageComponent" */ '../app/views/UpgradePackage/UpgradePackage').then(
            (module) => module.UpgradePackageComponent,
          ),
  },
  {
    path: '/bundles/:bundleId',
    name: RoutingRouteEnum.bundleOfferEdit,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.marketing.bundle_offers'],
    },
    component: () =>
      import(/* webpackChunkName: "bundleOfferEdit" */ '../bundles/views/BundleEdit/BundleEdit').then(
        (module) => module.BundlesViewsBundleEditComponent,
      ),
  },
  {
    path: '/bundles',
    name: RoutingRouteEnum.bundleOffers,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.marketing.bundle_offers'],
    },
    component: () =>
      import(/* webpackChunkName: "bundleOffers" */ '../bundles/views/Bundles/Bundles').then(
        (module) => module.BundlesViewsBundlesComponent,
      ),
  },
  {
    path: '/fostering-ngo',
    name: RoutingRouteEnum.fosteringNgo,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.marketing.fostering_ngo'],
    },
    component: () =>
      import(
        /* webpackChunkName: "fosteringNgo" */ '../fostering-ngo/views/FosteringNgoCreation/FosteringNgoCreation'
      ).then((module) => module.FosteringNgoViewsCreationComponent),
  },
  {
    path: '/affiliates',
    name: RoutingRouteEnum.affiliateList,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.marketing.affiliate'],
    },
    component: () =>
      import(/* webpackChunkName: "affiliateList" */ '../affiliate/views/AffiliateList/AffiliateList').then(
        (module) => module.AffiliateViewsListView,
      ),
  },
  {
    path: '/qitaf-program',
    name: RoutingRouteEnum.qitafProgram,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.marketing.qitaf'],
    },
    component: () =>
      import(/* webpackChunkName: "QitafProgram" */ '../qitaf-program/views/QitafProgram/QitafProgram.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/loyalty-program',
    name: RoutingRouteEnum.loyaltyProgram,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.marketing.loyalty'],
    },
    component: () =>
      import(/* webpackChunkName: "LoyaltyProgram" */ '../loyalty-program/views/LoyaltyProgram/LoyaltyProgram').then(
        (module) => module.LoyaltyProgramListView,
      ),
  },
  {
    path: '/landing-pages',
    name: RoutingRouteEnum.landingPages,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.marketing.landing_pages'],
    },
    component: () =>
      import(/* webpackChunkName: "landingPages" */ '../landing-pages/views/LandingPages.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/landing-pages/add',
    name: RoutingRouteEnum.landingPagesCreation,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.marketing.landing_pages'],
    },
    component: () =>
      import(
        /* webpackChunkName: "landingPagesCreation" */ '../landing-pages/views/CreateLandingPage/CreateLandingPage'
      ).then((module) => module.LandingPagesViewCreateLandingPageComponent),
  },
  {
    path: '/landing-pages/edit',
    name: RoutingRouteEnum.landingPagesEdit,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.marketing.landing_pages'],
    },
    component: () =>
      import(/* webpackChunkName: "EditLandingPage" */ '../landing-pages/views/EditLandingPage/EditLandingPage').then(
        (module) => module.LandingPagesViewEditLandingPageComponent,
      ),
  },
  {
    path: '/affiliate/create',
    name: RoutingRouteEnum.affiliateCreation,
    meta: {
      title:
        (i18n.messages[i18n.locale]['donations.header.add_donation.action'] as string) +
        ' ' +
        i18n.messages[i18n.locale]['affiliate.common.affiliate'],
    },
    component: () =>
      isFeatureAvailable('affiliate_marketing.add_affiliate')
        ? import(
            /* webpackChunkName: "affiliateCreation" */ '../affiliate/views/AffiliateCreation/AffiliateCreation'
          ).then((module) => module.AffiliateViewsCreationView)
        : import(/* webpackChunkName: "UpgradePackageComponent" */ '../app/views/UpgradePackage/UpgradePackage').then(
            (module) => module.UpgradePackageComponent,
          ),
  },
  {
    path: '/affiliate/:affiliateId',
    name: RoutingRouteEnum.affiliateDetails,
    meta: {
      title: i18n.messages[i18n.locale]['affiliate.details.title'],
    },
    component: () =>
      import(/* webpackChunkName: "affiliateDetails" */ '../affiliate/views/AffiliateDetails/AffiliateDetails').then(
        (module) => module.AffiliateViewsDetailsView,
      ),
  },
  {
    path: '/abandoned-carts',
    name: RoutingRouteEnum.abandonedCarts,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.marketing.abandoned_cart'],
    },
    component: () =>
      import(
        /* webpackChunkName: "abandonedCarts" */ '../abandoned-carts/views/AbandonedCartsList/AbandonedCartsList'
      ).then((module) => module.AbandonedCartsList),
  },
  {
    path: '/abandoned-carts/:cartId',
    name: RoutingRouteEnum.abandonedCartsDetails,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.marketing.abandoned_cart'],
    },
    component: () =>
      import(
        /* webpackChunkName: "abandonedCarts" */ '../abandoned-carts/views/AbandonedCartsDetails/AbandonedCartsDetails'
      ).then((module) => module.AbandonedCartsDetails),
  },
  {
    path: '/zidpay',
    name: RoutingRouteEnum.zidpay,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zidpay'],
    },
    component: () =>
      import(/* webpackChunkName: "zidpay" */ '../zidpay/views/Zidpay/Zidpay').then(
        (module) => module.ZidpayViewsZidpayComponent,
      ),
  },
  {
    path: '/zidpay/info',
    name: RoutingRouteEnum.zidpayMarketing,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zidpay.marketing'],
    },
    component: () =>
      import(/* webpackChunkName: "zidpayMarketing" */ '../zidpay/views/ZidpayMarketing/ZidpayMarketing').then(
        (module) => module.ZidpayViewsZidpayMarketingComponent,
      ),
  },
  {
    path: '/zidpay/activation',
    name: RoutingRouteEnum.zidpayActivation,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zidpay.activation'],
    },
    component: () =>
      import(/* webpackChunkName: "zidpayActivation" */ '../zidpay/views/ZidpayActivation/ZidpayActivation').then(
        (module) => module.ZidpayViewsZidpayActivationComponent,
      ),
  },
  {
    path: '/zidpay/activation/confirmation',
    name: RoutingRouteEnum.zidpayActivationConfirmation,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zidpay.activation.confirmation'],
    },
    component: () =>
      import(
        /* webpackChunkName: "zidpayActivationConfirmation" */ '../zidpay/views/ZidpayActivation/Confirmation/ZidpayActivationConfirmation'
      ).then((module) => module.ZidpayViewsZidpayActivationConfirmationComponent),
  },
  {
    path: '/zidpay/after-activation/',
    name: RoutingRouteEnum.zidpayAfterActivation,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zidpay.activation.activation'],
    },
    component: () =>
      import(
        /* webpackChunkName: "zidpayAfterActivation" */ '../zidpay/views/ZidpayActivation/AfterActivation/ZidpayActivationAfterActivation'
      ).then((module) => module.ZidpayViewsZidpayAfterActivationComponent),
  },
  {
    path: '/zidpay/transactions',
    name: RoutingRouteEnum.zidpayTransactions,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zidpay.transactions'],
    },
    component: () =>
      import(/* webpackChunkName: "zidpayTransactions" */ '../zidpay/views/Transactions/Transactions').then(
        (module) => module.ZidpayViewsTransactionsComponent,
      ),
  },
  {
    path: '/zidpay/paymentlinks',
    name: RoutingRouteEnum.zidpayPaymentLinks,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zidpay.paymentlinks'],
    },
    component: () =>
      import(/* webpackChunkName: "zidpayPaymentLinks" */ '../zidpay/views/PaymentLinks/PaymentLinks').then(
        (module) => module.ZidpayViewsPaymentLinksComponent,
      ),
  },
  {
    path: '/zidpay/deposits',
    name: RoutingRouteEnum.zidpayDeposits,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zidpay.deposits'],
    },
    component: () =>
      import(/* webpackChunkName: "zidpayDeposits" */ '../zidpay/views/AccountStatement/AccountStatement').then(
        (module) => module.ZidpayViewsAccountStatementComponent,
      ),
  },
  {
    path: '/zidpay/deposits/:depositId',
    name: RoutingRouteEnum.zidpayDeposit,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zidpay.deposits'],
    },
    component: () =>
      import(
        /* webpackChunkName: "zidpayDeposit" */ '../zidpay/views/AccountStatement/DepositDetails/DepositDetails'
      ).then((module) => module.ZidpayViewsAccountStatementDepositDetailsComponent),
  },
  {
    path: '/zidpay/transactions/:providerId?/:referenceId',
    name: RoutingRouteEnum.zidpayTransaction,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zidpay.transactions'],
      viewType: DetailsPageViewTypeEnum.transactions,
    },
    component: () =>
      import(/* webpackChunkName: "zidpayTransaction" */ '../zidpay/views/Transactions/Details').then(
        (module) => module.ZidpayViewsZidpayTransactionAndPaymentLinkDetailsComponent,
      ),
  },
  {
    path: '/zidpay/paymentlink/:providerId?/:referenceId',
    name: RoutingRouteEnum.zidpayPaymentLink,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zidpay.transactions'],
      viewType: DetailsPageViewTypeEnum.paymentLink,
    },
    component: () =>
      import(/* webpackChunkName: "zidpayPaymentLink" */ '../zidpay/views/Transactions/Details').then(
        (module) => module.ZidpayViewsZidpayTransactionAndPaymentLinkDetailsComponent,
      ),
  },
  {
    path: '/zidpay/settings',
    name: RoutingRouteEnum.zidpaySettings,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zidpay.settings'],
    },
    component: () =>
      import(/* webpackChunkName: "zidpaySettings" */ '../zidpay/views/ZidpaySettings/ZidpaySettings').then(
        (module) => module.ZidpayViewsZidpaySettingsComponent,
      ),
  },
  {
    path: '/mazeed',
    name: RoutingRouteEnum.mazeed,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.mazeed'],
    },
    component: () =>
      import(/* webpackChunkName: "MazeedViewsMainPageComponent" */ '../mazeed/views/MainPage/MainPage').then(
        (module) => module.MazeedViewsMainPageComponent,
      ),
  },
  {
    path: '/mazeed/settings',
    name: RoutingRouteEnum.mazeedSettings,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.mazeed'],
    },
    component: () =>
      import(
        /* webpackChunkName: "MazeedViewsSettingsPageComponent" */ '../mazeed/views/SettingsPage/SettingsPage'
      ).then((module) => module.MazeedViewsSettingsPageComponent),
  },
  {
    path: '/mazeed/settlement-invoice/:id',
    name: RoutingRouteEnum.mazeedSettlementInvoice,
    meta: {
      title: i18n.messages[i18n.locale]['mazeed.store_updates.page_title'],
    },
    component: () =>
      import(
        /* webpackChunkName: "MazeedViewsInvoiceDetailsComponent" */ '../mazeed/views/InvoiceDetails/InvoiceDetails'
      ).then((module) => module.MazeedViewsInvoiceDetailsComponent),
  },
  {
    path: '/mazeed/settlements',
    name: RoutingRouteEnum.mazeedSettlements,
    meta: {
      title: i18n.messages[i18n.locale]['mazeed.analysis_page.store.earnings_title'],
    },
    component: () =>
      import(/* webpackChunkName: "MazeedViewsSettlementsComponent" */ '../mazeed/views/Settlemets/Settlements').then(
        (module) => module.MazeedViewsSettlementsComponent,
      ),
  },
  {
    path: '/finance',
    name: RoutingRouteEnum.finance,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.finance'],
    },
    component: (): Promise<typeof FinanceEntryPointComponent> =>
      import(/* webpackChunkName: "finance" */ '../Finance/views/FinanceEntryPoint/FinanceEntryPoint').then(
        (module) => module.FinanceEntryPointComponent,
      ),
  },
  {
    path: '/finance/marketing',
    name: RoutingRouteEnum.financeMarketing,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.finance'],
    },
    component: (): Promise<typeof FinanceMarketingComponent> =>
      import(/* webpackChunkName: "financeMarketing" */ '../Finance/views/FinanceMarketing/FinanceMarketing').then(
        (module) => module.FinanceMarketingComponent,
      ),
  },
  {
    path: '/finance/activation',
    name: RoutingRouteEnum.financeActivation,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.finance'],
    },
    component: (): Promise<typeof FinanceActivationComponent> =>
      import(/* webpackChunkName: "financeActivation" */ '../Finance/views/FinanceActivation/FinanceActivation').then(
        (module) => module.FinanceActivationComponent,
      ),
  },
  {
    path: '/finance/info',
    name: RoutingRouteEnum.financeLanding,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.finance'],
    },
    component: (): Promise<typeof FinanceLandingComponent> =>
      import(/* webpackChunkName: "FinanceLanding" */ '../Finance/views/FinanceLanding/FinanceLanding').then(
        (module) => module.FinanceLandingComponent,
      ),
  },
  {
    path: '/theme-store',
    name: RoutingRouteEnum.themeStore,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.interface_options.theme_store'],
    },
    component: () =>
      import(/* webpackChunkName: "themeStore" */ '../theme-store/views/ThemeStore').then(
        (module) => module.ThemeStoreComponent,
      ),
  },
  {
    path: '/theme-Added/:themeId',
    name: RoutingRouteEnum.themeAdded,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.interface_options.theme_store'],
    },
    component: () =>
      import(/* webpackChunkName: "themeAdded" */ '../theme-store/views/ThemeAdded').then(
        (module) => module.ThemeAddedComponent,
      ),
  },
  {
    path: '/customers',
    name: RoutingRouteEnum.customers,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.customer.title'],
    },
    component: () =>
      import(/* webpackChunkName: "customers" */ '../customers/views/Customers/Customers').then(
        (module) => module.CustomersViewsCustomersComponent,
      ),
  },
  {
    path: '/customers-groups',
    name: RoutingRouteEnum.customersGroups,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.customer.title'],
    },
    component: () =>
      import(/* webpackChunkName: "customersGroups" */ '../customers/views/CustomersGroups/CustomersGroups').then(
        (module) => module.CustomersViewsCustomersGroupsComponent,
      ),
  },
  {
    path: '/customers/:customerId',
    name: RoutingRouteEnum.customer,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.customer.title'],
    },
    component: () =>
      import(/* webpackChunkName: "customer" */ '../customers/views/Customer/Customer').then(
        (module) => module.CustomersViewsCustomerComponent,
      ),
  },
  {
    path: '/customers-groups/:groupId',
    name: RoutingRouteEnum.customersGroup,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.customer.title'],
    },
    component: () =>
      import(/* webpackChunkName: "customersGroups" */ '../customers/views/CustomersGroup/CustomersGroup').then(
        (module) => module.CustomersViewsCustomersGroupComponent,
      ),
  },
  {
    path: '/customers/:customerId/addresses',
    name: RoutingRouteEnum.customerAddresses,
    meta: {
      title: i18n.messages[i18n.locale]['customers.profile.addresses.title'],
    },
    component: () =>
      import(/* webpackChunkName: "customerAddresses" */ '../customers/views/Addresses/Addresses').then(
        (module) => module.CustomersViewsAddressesComponent,
      ),
  },
  {
    path: '/donations',
    name: RoutingRouteEnum.donations,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.donations.title'],
    },
    component: () =>
      import(/* webpackChunkName: "donations" */ '../donations/views/Donations/Donations').then(
        (module) => module.DonationsViewsDonationsComponent,
      ),
  },
  {
    path: '/donations/create',
    name: RoutingRouteEnum.createDonation,
    meta: {
      title: i18n.messages[i18n.locale]['donations.header.add_donation.action.title'],
    },
    component: () =>
      import(/* webpackChunkName: "editDonation" */ '../donations/views/Donation/Donation.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/donations/:donationId',
    name: RoutingRouteEnum.editDonation,
    meta: {
      title: i18n.messages[i18n.locale]['donations.header.edit_donation.title'],
    },

    component: () =>
      import(/* webpackChunkName: "editDonation" */ '../donations/views/Donation/Donation.vue').then(
        (module) => module.default,
      ),
  },

  {
    path: '/account/settings/store',
    name: RoutingRouteEnum.settingsStore,
    meta: {
      title: i18n.messages[i18n.locale]['common.page_header.title'],
    },
    component: () =>
      import(/* webpackChunkName: "settingsStore" */ '../settings/views/accounts/Store').then(
        (module) => module.SettingsStoreViewssettingsStoreComponent,
      ),
  },
  {
    path: '/coupons',
    name: RoutingRouteEnum.coupons,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.marketing.coupons'],
    },
    component: () =>
      import(/* webpackChunkName: "coupons" */ '../coupons/views/Coupons/Coupons').then(
        (module) => module.CouponsViewsCouponsComponent,
      ),
  },
  {
    path: '/coupons/report',
    name: RoutingRouteEnum.couponsReports,
    meta: {
      title: i18n.messages[i18n.locale]['coupons.reports.header.title'],
    },
    component: () =>
      isFeatureAvailable('coupons.analytics')
        ? import(/* webpackChunkName: "couponsReport" */ '../coupons/views/CouponsReports/CouponsReports').then(
            (module) => module.CouponsViewsCouponsReportsComponent,
          )
        : import(/* webpackChunkName: "UpgradePackageComponent" */ '../app/views/UpgradePackage/UpgradePackage').then(
            (module) => module.UpgradePackageComponent,
          ),
  },
  {
    path: '/coupons/add',
    name: RoutingRouteEnum.addCoupon,
    meta: {
      title: i18n.messages[i18n.locale]['coupons.header.add_coupon.title'],
    },
    component: () =>
      import(/* webpackChunkName: "addCoupon" */ '../coupons/views/AddCoupon/AddCoupon').then(
        (module) => module.CouponsViewsAddCouponComponent,
      ),
  },
  {
    path: '/coupons/edit/:couponId',
    name: RoutingRouteEnum.editCoupon,
    meta: {
      title: i18n.messages[i18n.locale]['coupons.header.edit_coupon.title'],
    },
    component: () =>
      import(/* webpackChunkName: "editCoupon" */ '../coupons/views/EditCoupon/EditCoupon').then(
        (module) => module.CouponsViewsEditCouponComponent,
      ),
  },
  {
    path: '/coupons/:couponId',
    name: RoutingRouteEnum.viewCoupon,
    meta: {
      title: i18n.messages[i18n.locale]['coupons.header.view_coupon.title'],
    },
    component: () =>
      import(/* webpackChunkName: "viewCoupon" */ '../coupons/views/ViewCoupon/ViewCoupon').then(
        (module) => module.CouponsViewsViewCouponComponent,
      ),
  },
  {
    path: '/account/settings/inventory-addresses',
    name: RoutingRouteEnum.settingsInventoryAddresses,
    meta: {
      title: i18n.messages[i18n.locale]['settings.inventory_addresses.page_title'],
    },
    component: () =>
      import(/* webpackChunkName: "inventory" */ '../settings/views/Inventory/InventoryAddresses').then(
        (module) => module.SettingsStoreViewsInventoryInventoryAddresses,
      ),
    beforeEnter: inventoryAddressesGuard,
  },
  {
    path: '/account/settings/inventory-addresses/create',
    name: RoutingRouteEnum.settingsInventoryCreation,
    meta: {
      title: i18n.messages[i18n.locale]['settings.inventory_addresses.new_address'],
    },
    component: () =>
      import(
        /* webpackChunkName: "settingsInventoryCreation" */ '../settings/views/Inventory/EditableAddressCreationForm'
      ).then((module) => module.SettingsStoreViewsInventoryEditableAddressCreationForm),
  },
  {
    path: '/account/settings/inventory-addresses/:addressId',
    name: RoutingRouteEnum.settingsInventoryAddress,
    meta: {
      title: i18n.messages[i18n.locale]['settings.inventory_addresses.edit_address'],
    },
    component: () =>
      import(
        /* webpackChunkName: "settingsInventoryAddress" */ '../settings/views/Inventory/EditableAddressCreationForm'
      ).then((module) => module.SettingsStoreViewsInventoryEditableAddressCreationForm),
  },
  {
    path: '/account/settings/order-product-settings',
    name: RoutingRouteEnum.settingsOrdersProductsSettings,
    meta: {
      title: i18n.messages[i18n.locale]['settings.order_product_spec'],
    },
    component: () =>
      import(
        /* webpackChunkName: "OrdersProductsSpec" */ '../settings/views/OrdersProductsSettings/OrdersProductsSettings'
      ).then((module) => module.SettingsStoreViewsOrdersProductsSettings),
  },
  {
    path: '/account/settings/merchant',
    name: RoutingRouteEnum.settingsAccount,
    meta: {
      title: i18n.messages[i18n.locale]['settings.account_settings.title'],
    },
    component: () =>
      import(/* webpackChunkName: "settingsAccount" */ '../settings/views/AccountSettings/AccountSettings').then(
        (module) => module.SettingsViewsAccountSettingsComponent,
      ),
  },
  {
    path: '/account/complete-store-info',
    name: RoutingRouteEnum.completeStoreInfo,
    meta: {
      title: i18n.messages[i18n.locale]['common.subscription'],
    },
    component: () =>
      import(/* webpackChunkName: "completeStoreInfo" */ '../settings/views/CompleteStoreInfo/CompleteStoreInfo').then(
        (module) => module.SettingsViewsCompleteStoreInfoComponent,
      ),
  },
  {
    path: '/account/settings/store-link',
    name: RoutingRouteEnum.StoreLink,
    meta: {
      title: i18n.messages[i18n.locale]['common.about.store_en_title'],
    },
    component: () =>
      import(/* webpackChunkName: "storeLink" */ '../settings/views/StoreLink/StoreLink.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/account/settings/verify-business',
    name: RoutingRouteEnum.StoreSaudiBusinessVerify,
    meta: {
      title: i18n.messages[i18n.locale]['new_merchant_steps_list.step_store_ready.store_verify.platform_verify'],
    },
    component: () =>
      import(
        /* webpackChunkName: "storeSaudiBusinessVerify" */ '../settings/views/StoreSaudiBusinessVerify/StoreSaudiBusinessVerify.vue'
      ).then((module) => module.default),
  },
  {
    path: '/account/settings/store-contact-form',
    name: RoutingRouteEnum.StoreSaudiContactInfoForm,
    meta: {
      // TODO: update title
      title: i18n.messages[i18n.locale]['new_merchant_steps_list.step_store_ready.store_verify.platform_verify'],
    },
    component: () =>
      import(
        /* webpackChunkName: "StoreContactInfoForm" */ '../settings/views/StoreContactInfoForm/StoreContactInfoForm.vue'
      ).then((module) => module.default),
  },
  {
    path: '/account/settings/store-vat-form',
    name: RoutingRouteEnum.StoreSaudiVatInfoForm,
    meta: {
      title: i18n.messages[i18n.locale]['store.kyc.vat_invoice_generation.title'],
    },
    component: () =>
      import(
        /* webpackChunkName: "StoreVatInfoForm" */ '../settings/views/StoreBusinessVatInfoForm/StoreBusinessVatInfoForm.vue'
      ).then((module) => module.default),
  },
  {
    path: '/store-design',
    name: RoutingRouteEnum.storeDesign,
    meta: {
      title: i18n.messages[i18n.locale]['common.store.identity'],
    },
    component: () =>
      import(/* webpackChunkName: "storeDesign" */ '../settings/views/StoreDesign/StoreDesign').then(
        (module) => module.SettingsViewsStoreDesignComponent,
      ),
  },
  {
    path: '/market',
    name: RoutingRouteEnum.appMarket,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.app_market.title'],
    },
    beforeEnter: (to: Route, _: Route, next: Function): void => {
      // trigger GTM event
      helpersTriggerGTMEventAction(CommonHelpersTriggerGTMEventsNamesEnum.linkClick, {
        action: 'App Market Page Viewed',
        location: CommonHelpersTriggerGtmEventsLocationsEnum.appMarket,
      });

      next();
    },
    component: () =>
      import(/* webpackChunkName: "appMarketHome" */ '../app-market/views/AppMarket/AppMarket').then(
        (module) => module.AppMarketViewsAppMarketComponent,
      ),
  },
  {
    path: '/market/applications',
    name: RoutingRouteEnum.appMarketBrowseApps,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.app_market.title'],
    },
    component: () =>
      import(/* webpackChunkName: "appMarketBrowse" */ '../app-market/views/BrowseApps/BrowseApps').then(
        (module) => module.AppMarketViewsBrowseAppsComponent,
      ),
  },
  {
    path: '/market/app/:id/:purchaseId?/:zidService?/:error_code?/:success_code?',
    name: RoutingRouteEnum.appMarketDetails,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.app_market.title'],
    },
    component: () =>
      import(/* webpackChunkName: "appMarketDetails" */ '../app-market/views/AppDetails/AppDetails').then(
        (module) => module.AppMarketViewsAppDetailsComponent,
      ),
  },
  {
    path: '/market/embedded/:id',
    name: RoutingRouteEnum.appMarketEmbedded,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.app_market.title'],
    },
    component: () =>
      import(/* webpackChunkName: "appMarketDetails" */ '../app-market/views/EmbeddedApp/EmbeddedApp').then(
        (module) => module.EmbeddedApp,
      ),
  },
  {
    path: '/market/my-apps',
    name: RoutingRouteEnum.appMarketMyApps,
    meta: {
      title: i18n.messages[i18n.locale]['app_market.my_apps.title'],
    },
    component: () =>
      import(/* webpackChunkName: "appMarketManagement" */ '../app-market/views/MyApps/MyApps').then(
        (module) => module.AppMarketViewsMyAppsComponent,
      ),
  },
  {
    path: '/market/apps-collection/:id',
    name: RoutingRouteEnum.appMarketAppsCollection,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.app_market.title'],
    },
    component: () =>
      import(/* webpackChunkName: "appMarketHome" */ '../app-market/views/AppsCollection/AppsCollection').then(
        (module) => module.AppMarketViewsAppsCollectionComponent,
      ),
  },
  {
    path: '/marketplace-connect',
    name: RoutingRouteEnum.marketplace,
    meta: {
      title: i18n.messages[i18n.locale]['marketplace.title'],
    },
    beforeEnter: marketplaceConnectGuard,
    component: () =>
      import(/* webpackChunkName: "marketplace" */ '../marketplace-connect/views/Marketplace/Marketplace.vue').then(
        (module) => module.default,
      ),
  },

  // Mirgrate-old-inventroy-CR_2352 => redirect to new inventory
  {
    path: '/account/settings/inventory-addresses-v2',
    name: RoutingRouteEnum.settingsInventoryAddressesV2,
    meta: {
      title: i18n.messages[i18n.locale]['settings.inventory_addresses.page_title'],
    },
    component: () =>
      import(
        /* webpackChunkName: "SettingsStoreViewsInventoryInventoryAddresses" */ '../settings/views/Inventory-v2/InventoryAddresses'
      ).then((module) => module.SettingsStoreViewsInventoryInventoryAddresses),
  },
  {
    path: '/account/settings/inventory-addresses-v2/priority',
    name: RoutingRouteEnum.settingsInventoryAddressesV2Priority,
    meta: {
      title: i18n.messages[i18n.locale]['settings.inventory_addresses.page_title'],
    },
    beforeEnter(to: Route, from: Route, next: Function): void {
      if (!isFeatureAvailable('inventory_locations.priority_for_withdrawal_from_warehouse')) window.history.back();
      else next();
    },
    component: () =>
      import(
        /* webpackChunkName: "SettingsStoreViewsInventoryInventoryAddresses" */ '../settings/views/Inventory-v2/InventoryAddressesPriority'
      ).then((module) => module.SettingsStoreViewsInventoryInventoryAddressesPriority),
  },
  {
    path: '/account/settings/inventory-addresses-v2/stocks',
    name: RoutingRouteEnum.settingsInventoryAddressesV2Stocks,
    meta: {
      title: i18n.messages[i18n.locale]['settings.inventory_addresses.stocks'],
    },
    beforeEnter(to: Route, from: Route, next: Function): void {
      if (!isFeatureAvailable('inventory_locations.stock_managment')) window.history.back();
      else next();
    },
    component: () =>
      import(
        /* webpackChunkName: "SettingsStoreViewsInventoryStockManagement" */ '../settings/views/Inventory-v2/StockManagement/StockManagement'
      ).then((module) => module.SettingsStoreViewsInventoryStockManagement),
  },
  {
    path: '/account/settings/inventory-addresses-v2/create',
    name: RoutingRouteEnum.settingsInventoryCreationV2,
    meta: {
      title: i18n.messages[i18n.locale]['settings.inventory_addresses.new_address'],
    },
    component: () =>
      import(
        /* webpackChunkName: "SettingsStoreViewsInventoryEditableAddressCreationForm" */ '../settings/views/Inventory-v2/EditableAddressCreationForm'
      ).then((module) => module.SettingsStoreViewsInventoryEditableAddressCreationForm),
  },
  {
    path: '/account/settings/inventory-addresses-v2/stocktaking',
    name: RoutingRouteEnum.settingsInventoryAddressV2Stocktaking,
    meta: {
      title: i18n.messages[i18n.locale]['settings.inventory_addresses.stocktaking.title'],
    },
    beforeEnter(to: Route, from: Route, next: Function): void {
      if (!isOldFeatureAvailable('inventory_locations.stocktaking')) window.history.back();
      else next();
    },
    component: () =>
      import(
        /* webpackChunkName: "StocktakingList" */ '../settings/views/Inventory-v2/Stocktaking/StocktakingList/StocktakingList.vue'
      ).then((module) => module.default),
  },
  {
    path: '/account/settings/inventory-addresses-v2/stocktaking/create',
    name: RoutingRouteEnum.settingsInventoryAddressV2CreateStocktaking,
    meta: {
      title: i18n.messages[i18n.locale]['settings.inventory_addresses.stocktaking.title'],
    },
    beforeEnter(to: Route, from: Route, next: Function): void {
      if (!isOldFeatureAvailable('inventory_locations.stocktaking')) window.history.back();
      else next();
    },
    component: () =>
      import(
        /* webpackChunkName: "CreateStocktaking" */ '../settings/views/Inventory-v2/Stocktaking/CreateStocktaking/Create.vue'
      ).then((module) => module.default),
  },
  {
    path: '/account/settings/inventory-addresses-v2/stocktaking/create/:countId',
    name: RoutingRouteEnum.settingsInventoryAddressV2CreateStocktakingCount,
    meta: {
      title: i18n.messages[i18n.locale]['settings.inventory_addresses.stocktaking.title'],
    },
    beforeEnter(to: Route, from: Route, next: Function): void {
      if (!isOldFeatureAvailable('inventory_locations.stocktaking')) window.history.back();
      else next();
    },
    component: () =>
      import(
        /* webpackChunkName: "CreateStocktaking" */ '../settings/views/Inventory-v2/Stocktaking/CreateStocktaking/Create.vue'
      ).then((module) => module.default),
  },
  {
    path: '/account/settings/inventory-addresses-v2/stocktaking/:countId',
    name: RoutingRouteEnum.settingsInventoryAddressV2StocktakingOverview,
    meta: {
      title: i18n.messages[i18n.locale]['settings.inventory_addresses.stocktaking.title'],
    },
    beforeEnter(to: Route, from: Route, next: Function): void {
      if (!isOldFeatureAvailable('inventory_locations.stocktaking')) window.history.back();
      else next();
    },
    component: () =>
      import(
        /* webpackChunkName: "StocktakingOverview" */ '../settings/views/Inventory-v2/Stocktaking/StocktakingOverview/StocktakingOverview.vue'
      ).then((module) => module.default),
  },
  {
    path: '/account/settings/inventory-addresses-v2/:addressId',
    name: RoutingRouteEnum.settingsInventoryAddressV2,
    meta: {
      title: i18n.messages[i18n.locale]['settings.inventory_addresses.edit_address'],
    },
    component: () =>
      import(
        /* webpackChunkName: "SettingsStoreViewsInventoryEditableAddressCreationForm" */ '../settings/views/Inventory-v2/EditableAddressCreationForm'
      ).then((module) => module.SettingsStoreViewsInventoryEditableAddressCreationForm),
  },
  {
    path: '/account/settings/inventory-transfer-requests/create',
    name: RoutingRouteEnum.settingsInventoryTransferRequestCreation,
    meta: {
      title: i18n.messages[i18n.locale]['settings.inventory_management.transfer_requests.create'],
    },
    component: () =>
      import(
        /* webpackChunkName: "SettingsInventoryTransferRequestCreation" */ '../settings/views/Inventory-v2/TransferRequests/TransferRequestCreation'
      ).then((module) => module.SettingsInventoryTransferRequestCreation),
  },
  {
    path: '/account/settings/inventory-transfer-requests',
    name: RoutingRouteEnum.settingsInventoryTransferRequests,
    meta: {
      title: i18n.messages[i18n.locale]['settings.inventory_management.transfer_requests'],
    },
    component: () =>
      import(
        /* webpackChunkName: "SettingsStoreViewsInventoryTransferRequests" */ '../settings/views/Inventory-v2/TransferRequests/TransferRequests'
      ).then((module) => module.SettingsStoreViewsInventoryTransferRequests),
  },
  {
    path: '/account/settings/inventory-transfer-requests/:requestId',
    name: RoutingRouteEnum.settingsInventoryTransferRequestDetails,
    meta: {
      title: i18n.messages[i18n.locale]['settings.inventory_management.transfer_requests'],
    },
    component: () =>
      import(
        /* webpackChunkName: "SettingsInventoryTransferRequestDetails" */ '../settings/views/Inventory-v2/TransferRequests/TransferRequestDetails'
      ).then((module) => module.SettingsInventoryTransferRequestDetails),
  },
  {
    path: '/account/settings/inventory-transfer-requests/:requestId/edit',
    name: RoutingRouteEnum.settingsInventoryTransferRequestModification,
    meta: {
      title: i18n.messages[i18n.locale]['settings.inventory_management.transfer_requests'],
    },
    component: () =>
      import(
        /* webpackChunkName: "SettingsInventoryTransferRequestModification" */ '../settings/views/Inventory-v2/TransferRequests/TransferRequestModification'
      ).then((module) => module.SettingsInventoryTransferRequestModification),
  },
  {
    path: '/account/settings/inventory-transfer-requests/:requestId/receive',
    name: RoutingRouteEnum.settingsInventoryTransferRequestReceive,
    meta: {
      title: i18n.messages[i18n.locale]['settings.inventory_management.transfer_requests'],
    },
    component: () =>
      import(
        /* webpackChunkName: "SettingsInventoryTransferRequestReceive" */ '../settings/views/Inventory-v2/TransferRequests/TransferRequestReceive'
      ).then((module) => module.SettingsInventoryTransferRequestReceive),
  },
  // Keep not found handler at the bottom!
  {
    path: '/account/settings/checkout-options',
    name: RoutingRouteEnum.checkoutOptions,
    meta: {
      title: i18n.messages[i18n.locale]['settings.checkout_options.views.bread_title'],
    },
    component: () =>
      import(
        /* webpackChunkName: "settingsCheckoutOptions" */ '../settings/views/CheckoutOptions/CheckoutOptions'
      ).then((module) => module.SettingsViewsCheckoutOptionsComponent),
  },
  {
    path: '/account/settings/checkout-options/cutomize-customer-address',
    name: RoutingRouteEnum.cutomizeCustomerAddress,
    meta: {
      title: i18n.messages[i18n.locale]['settings.checkout_options.customize_customer_address.title'],
    },
    component: () =>
      import(
        /* webpackChunkName: "smsCampaigns" */ '../settings/views/CustomizeCustomerAddress/CustomizeCustomerAddress.vue'
      ).then((module) => module.default),
  },
  {
    path: '/subscription/packages',
    name: RoutingRouteEnum.subscriptionPackages,
    meta: {
      title: i18n.messages[i18n.locale]['common.subscription'],
    },
    beforeEnter: subscriptionWebManagerGuard,
    component: () =>
      import(
        /* webpackChunkName: "subscriptionPackages" */ '../subscription/views/SubscriptionPackages/SubscriptionPackages'
      ).then((module) => module.SubscriptionViewsSubscriptionPackagesComponent),
  },
  {
    path: '/subscription/checkout',
    name: RoutingRouteEnum.subscriptionsCheckout,
    meta: {
      title: i18n.messages[i18n.locale]['common.subscription'],
    },
    beforeEnter: isPackageAvailableToCheckout,
    component: () =>
      import(
        /* webpackChunkName: "subscriptionsCheckout" */ '../subscription/views/SubscriptionCheckout/SubscriptionCheckout'
      ).then((module) => module.SubscriptionViewsSubscriptionCheckoutComponent),
  },
  {
    path: '/purchases/thank-you/:purchaseId',
    name: RoutingRouteEnum.subscriptionsCheckoutThank,
    meta: {
      title: i18n.messages[i18n.locale]['common.subscription'],
    },
    component: () =>
      import(
        /* webpackChunkName: "subscriptionsCheckoutThank" */ '../subscription/views/SubscriptionCheckout/ThankYou/ThankYou'
      ).then((module) => module.SubscriptionViewsSubscriptionCheckoutThankYouComponent),
  },
  {
    path: '/subscriptions',
    name: RoutingRouteEnum.subscriptionsIndex,
    meta: {
      title: i18n.messages[i18n.locale]['common.subscription'],
    },
    component: () =>
      import(/* webpackChunkName: "subscriptionsIndex" */ '../subscription/views/Index/Index.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/subscription/purchases',
    name: RoutingRouteEnum.subscriptionPurchases,
    meta: {
      title: i18n.messages[i18n.locale]['common.subscription'],
    },
    component: () =>
      import(/* webpackChunkName: "subscriptionPurchases" */ '../subscription/views/Purchases/Purchases.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/subscription/addons',
    name: RoutingRouteEnum.subscriptionAddons,
    meta: {
      title: i18n.messages[i18n.locale]['common.subscription'],
    },
    component: () =>
      import(/* webpackChunkName: "subscriptionAddons" */ '../subscription/views/Addons/Addons.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/subscription/cards',
    name: RoutingRouteEnum.subscriptionSavedCards,
    meta: {
      title: i18n.messages[i18n.locale]['common.subscription'],
    },
    component: () =>
      import(/* webpackChunkName: "subscriptionSavedCards" */ '../subscription/views/SavedCards/SavedCards.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/subscription/cards/new',
    name: RoutingRouteEnum.subscriptionSaveCard,
    meta: {
      title: i18n.messages[i18n.locale]['common.subscription'],
    },
    component: () =>
      import(/* webpackChunkName: "subscriptionSaveCard" */ '../subscription/views/SavedCards/New.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/subscriptions/suspended',
    name: RoutingRouteEnum.subscriptionsSuspended,
    meta: {
      title: i18n.messages[i18n.locale]['common.subscription'],
    },
    component: () =>
      import(
        /* webpackChunkName: "subscriptionsSuspended" */ '../subscription/views/SubscriptionSuspended/SubscriptionSuspended'
      ).then((module) => module.SubscriptionViewsSubscriptionSuspendedComponent),
  },
  {
    path: '/account/settings/gifting-options',
    name: RoutingRouteEnum.giftOptions,
    meta: {
      title: i18n.messages[i18n.locale]['settings.gifting_options.views.bread_title'],
    },
    component: () =>
      import(/* webpackChunkName: "settingsGiftOptions" */ '../settings/views/GiftingOptions/GiftingOptions').then(
        (module) => module.SettingsViewsGiftOptionsComponent,
      ),
  },
  {
    path: '/zidpos',
    name: RoutingRouteEnum.zidpos,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zidpos'],
    },
    beforeEnter(to: Route, from: Route, next: Function): void {
      if (!hasActivatedPOS()) next({ name: RoutingRouteEnum.zidposPreSubscription });
      else if (!isPosReady() && from.name != RoutingRouteEnum.zidposOnboarding)
        next({ name: RoutingRouteEnum.zidposOnboarding });
      else next();
    },
    component: () =>
      import(/* webpackChunkName: "ZidPOSViewsHome" */ '../zidpos/views/Home/Home').then(
        (module) => module.ZidPOSViewsHome,
      ),
  },
  {
    path: '/zidpos/onboarding',
    name: RoutingRouteEnum.zidposOnboarding,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zidpos.onboarding'],
    },
    component: () =>
      import(/* webpackChunkName: "ZidPOSViewsOnboarding" */ '../zidpos/views/Onboarding/Onboarding').then(
        (module) => module.ZidPOSViewsOnboarding,
      ),
  },
  {
    path: '/zidpos/reports',
    name: RoutingRouteEnum.zidposReports,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zidpos'],
    },
    component: () =>
      import(/* webpackChunkName: "ZidPOSViewsReports" */ '../zidpos/views/Reports/Reports').then(
        (module) => module.ZidPOSViewsReports,
      ),
  },
  {
    path: '/zidpos/payment-methods',
    name: RoutingRouteEnum.zidposPaymentMethods,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zidpos'],
    },
    component: () =>
      import(/* webpackChunkName: "ZidPOSViewsPaymentMethods" */ '../zidpos/views/PaymentMethods/PaymentMethods').then(
        (module) => module.ZidPOSViewsPaymentMethods,
      ),
  },
  {
    path: '/zidpos/cashiers',
    name: RoutingRouteEnum.zidposCashiers,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zidpos'],
    },
    component: () =>
      import(/* webpackChunkName: "ZidPOSViewsCashiers" */ '../zidpos/views/Cashiers/Cashiers').then(
        (module) => module.ZidPOSViewsCashiers,
      ),
  },
  {
    path: '/zidpos/cashiers/add',
    name: RoutingRouteEnum.zidposNewCashier,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zidpos'],
    },
    beforeEnter(to: Route, _: Route, next: Function): void {
      if (!hasActivatedPOS()) next({ name: RoutingRouteEnum.zidposSubscribe });
      else next();
    },
    component: () =>
      import(/* webpackChunkName: "ZidPOSViewsNewCashier" */ '../zidpos/views/Cashiers/NewCashier').then(
        (module) => module.ZidPOSViewsNewCashier,
      ),
  },
  {
    path: '/zidpos/subscribe',
    name: RoutingRouteEnum.zidposSubscribe,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zidpos'],
    },
    beforeEnter(to: Route, _: Route, next: Function): void {
      if (hasActivatedPOS()) next({ name: RoutingRouteEnum.zidpos });
      else next();
    },
    component: () =>
      import(/* webpackChunkName: "ZidPOSViewsSubscription" */ '../zidpos/views/Subscription/Subscription').then(
        (module) => module.ZidPOSViewsSubscription,
      ),
  },
  {
    path: '/zidpos/overview',
    name: RoutingRouteEnum.zidposPreSubscription,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.zidpos'],
    },
    beforeEnter(to: Route, _: Route, next: Function): void {
      if (hasActivatedPOS()) next({ name: RoutingRouteEnum.zidpos });
      else next();
    },
    component: () =>
      import(
        /* webpackChunkName: "ZidPOSViewsPreSubscription" */ '../zidpos/views/PreSubscription/PreSubscription'
      ).then((module) => module.ZidPOSViewsPreSubscription),
  },
  // theme market
  {
    path: '/theme-market',
    name: RoutingRouteEnum.themeMarketHome,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.interface_options.theme_store'],
    },
    component: () =>
      import(/* webpackChunkName: "ThemeMarket" */ '../theme-market/views/home/ThemeMarketHome').then(
        (module) => module.ThemeMarketHome,
      ),
  },
  {
    path: '/theme-details/:id',
    name: RoutingRouteEnum.themeMarketDetails,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.interface_options.theme_store'],
    },
    component: () =>
      import(/* webpackChunkName: "ThemeMarket" */ '../theme-market/views/theme-details/ThemeDetails').then(
        (module) => module.ThemeDetails,
      ),
  },
  {
    path: '/theme-market-browse',
    name: RoutingRouteEnum.themeMarketBrowse,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.interface_options.theme_store'],
    },
    component: () =>
      import(/* webpackChunkName: "ThemeMarket" */ '../theme-market/views/browse/BrowseThemes').then(
        (module) => module.BrowseThemes,
      ),
  },
  //  /theme market
  {
    path: '/subscriptions/checkout',
    name: RoutingRouteEnum.subscriptionsCheckout,
    meta: {
      title: i18n.messages[i18n.locale]['common.subscription'],
    },
    beforeEnter: isPackageAvailableToCheckout,
    component: () =>
      import(
        /* webpackChunkName: "subscriptionsCheckout" */ '../subscription/views/SubscriptionCheckout/SubscriptionCheckout'
      ).then((module) => module.SubscriptionViewsSubscriptionCheckoutComponent),
  },
  {
    path: '/mobile-app',
    name: RoutingRouteEnum.mobileAppLandingPage,
    meta: {
      title: i18n.messages[i18n.locale]['mobile_app.landing_page_title'],
    },
    component: () => import('../mobile-app/views/MobileAppLandingPage/MobileAppLandingPage'),
  },
  {
    path: '/app-manager',
    name: RoutingRouteEnum.mobileAppManager,
    meta: {
      title: i18n.messages[i18n.locale]['mobile_app.manager_title'],
    },
    component: () =>
      import(
        /* webpackChunkName: "mobileAppManager" */ '../mobile-app/views/MobileAppManager/MobileAppSettings/MobileAppSettings'
      ).then((module) => module.MobileAppManager),
  },
  {
    path: '/app-manager/push-notifications',
    name: RoutingRouteEnum.mobileAppManagerPushNotifications,
    meta: {
      title: i18n.messages[i18n.locale]['mobile_app.manager_title'],
    },
    component: () =>
      import(
        /* webpackChunkName: "mobileAppManagerPushNotifications" */ '../mobile-app/views/MobileAppManager/MobileAppPushNotifications/MobileAppPushNotifications'
      ).then((module) => module.MobileAppPushNotifications),
  },
  {
    path: '/app-manager/discounts',
    name: RoutingRouteEnum.mobileAppManagerDiscounts,
    meta: {
      title: i18n.messages[i18n.locale]['mobile_app.manager_title'],
    },
    component: () =>
      import(
        /* webpackChunkName: "mobileAppManagerDiscounts" */ '../mobile-app/views/MobileAppManager/MobileAppDiscounts/MobileAppDiscounts'
      ).then((module) => module.MobileAppDiscounts),
  },
  {
    path: '/app-manager/banner',
    name: RoutingRouteEnum.mobileAppManagerBanner,
    meta: {
      title: i18n.messages[i18n.locale]['mobile_app.manager_title'],
    },
    component: () =>
      import(
        /* webpackChunkName: "mobileAppManagerBanner" */ '../mobile-app/views/MobileAppManager/MobileAppBanner/MobileAppBanner'
      ).then((module) => module.MobileAppBanner),
  },

  {
    path: '/ticketing',
    name: RoutingRouteEnum.ticketing,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.ticketing'],
    },
    component: () =>
      import(
        /* webpackChunkName: "Tickets" */ '../ticketing-system/views/Tickets/TicktingSystemViewsTicktesComponent'
      ).then((module) => module.TicketsComponent),
  },
  {
    path: '/ticketing/create',
    name: RoutingRouteEnum.createTicket,
    meta: {
      title: i18n.messages[i18n.locale]['ticketing.create'],
    },
    component: () =>
      import(/* webpackChunkName: "CreateTicket" */ '../ticketing-system/views/CreateTicket/CreateTicket').then(
        (module) => module.CreateTicket,
      ),
  },
  {
    path: '/ticketing/:ticketId',
    name: RoutingRouteEnum.ticketDetails,
    meta: {
      title: i18n.messages[i18n.locale]['ticketing.details'],
    },
    component: () =>
      import(/* webpackChunkName: "TicketDetails" */ '../ticketing-system/views/TicketDetails/TicketDetails').then(
        (module) => module.TicketDetails,
      ),
  },
  {
    path: '/promotional-messages',
    name: RoutingRouteEnum.promotionalMessages,
    meta: {
      title: i18n.messages[i18n.locale]['promotional_messages.title'],
    },
    component: () =>
      import(
        /* webpackChunkName: "PromotionalMessagesViewsComponent" */ '../promotional-messages/views/PromotionalMessages/PromotionalMessagesViewsComponent'
      ).then((module) => module.PromotionalMessagesViewsComponent),
  },
  {
    path: '/abandoned-carts-messages',
    name: RoutingRouteEnum.abandonedCartsMessages,
    meta: {
      title: i18n.messages[i18n.locale]['abandoned_carts_messages.title'],
    },
    component: () =>
      import(
        /* webpackChunkName: "AbandonedCartsMessagesViewsComponent" */ '../promotional-messages/views/AbandonedCartsMessages/AbandonedCartsMessagesViewsComponent'
      ).then((module) => module.AbandonedCartsMessagesViewsComponent),
  },
  {
    path: '/abandoned-carts-messages/create',
    name: RoutingRouteEnum.abandonedCartsCreateMessage,
    meta: {
      title: i18n.messages[i18n.locale]['abandoned_carts_messages.create.title'],
    },
    component: () =>
      isFeatureAvailable('abandoned_carts.send_reminder_message')
        ? import(
            /* webpackChunkName: "AbandonedCartsMessagesViewsComponent" */ '../promotional-messages/views/CreateMessage/CreateMessageViewsComponent'
          ).then((module) => module.CreateMessageViewsComponent)
        : import(/* webpackChunkName: "UpgradePackageComponent" */ '../app/views/UpgradePackage/UpgradePackage').then(
            (module) => module.UpgradePackageComponent,
          ),
  },
  {
    path: '/sms-campaigns',
    name: RoutingRouteEnum.smsCampaigns,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.marketing.sms_campaigns'],
    },
    component: () =>
      import(/* webpackChunkName: "smsCampaigns" */ '../sms-campaigns/views/Index/Index.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/sms-campaigns/pricing',
    name: RoutingRouteEnum.smsCampaignsPricing,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.marketing.sms_campaigns'],
    },
    component: () =>
      import(/* webpackChunkName: "smsCampaignsPricing" */ '../sms-campaigns/views/Pricing/Pricing.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/sms-campaigns/create',
    name: RoutingRouteEnum.createSmsCampaign,
    meta: {
      title: i18n.messages[i18n.locale]['fostering_ngo.header.create_new_campaign.label'],
    },
    component: () =>
      isFeatureAvailable('sms_campaigns.create_a_new_campaign')
        ? import(/* webpackChunkName: "createSmsCampaign" */ '../sms-campaigns/views/Create/Create.vue').then(
            (module) => module.default,
          )
        : import(/* webpackChunkName: "UpgradePackageComponent" */ '../app/views/UpgradePackage/UpgradePackage').then(
            (module) => module.UpgradePackageComponent,
          ),
  },
  {
    path: '/sms-campaigns/:campaignId',
    name: RoutingRouteEnum.viewSmsCampaign,
    meta: {
      title: i18n.messages[i18n.locale]['sms_campaigns.campaign.view'],
    },
    component: () =>
      import(/* webpackChunkName: "viewSmsCampaign" */ '../sms-campaigns/views/View/View.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/sms-campaigns/:campaignId/edit',
    name: RoutingRouteEnum.editSmsCampaign,
    meta: {
      title: i18n.messages[i18n.locale]['sms_campaigns.campaign.edit'],
    },
    component: () =>
      import(/* webpackChunkName: "editSmsCampaign" */ '../sms-campaigns/views/Edit/Edit.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/products/stock-history',
    name: RoutingRouteEnum.productsStockHistory,
    meta: {
      title: i18n.messages[i18n.locale]['products.quantity_logs.title'],
    },
    component: () =>
      import(
        /* webpackChunkName: "ProductsViewsProductsStockHistory" */ '../products/views/StockHistory/StockHistory'
      ).then((module) => module.ProductsViewsProductsStockHistory),
  },
  {
    path: '/products/filtration',
    name: RoutingRouteEnum.filtrationAttribute,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.products.filtration'],
    },
    component: () =>
      import(
        /* webpackChunkName: "filtrationAttribute" */ '../products/views/Products/FiltrationAttribute/FiltrationAttribute'
      ).then((module) => module.FiltrationAttributeViewsProductsFiltrationAttributeComponent),
  },
  {
    path: '/products/create',
    name: RoutingRouteEnum.createProduct,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.products.title'],
    },
    component: () =>
      import(/* webpackChunkName: "createProduct" */ '../products/views/Create/Create.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/products/grouped',
    name: RoutingRouteEnum.groupedProduct,
    meta: {
      title: i18n.messages[i18n.locale]['grouped_products.title'],
    },
    component: () =>
      import(/* webpackChunkName: "createGroupedProduct" */ '../products/views/Create/Create.vue').then(
        (module) => module.default,
      ),
    beforeEnter: hasGroupedProducts,
  },
  {
    path: '/products/grouped/:productId',
    name: RoutingRouteEnum.editGroupedProduct,
    meta: {
      title: i18n.messages[i18n.locale]['grouped_products.title'],
    },
    component: () =>
      import(/* webpackChunkName: "editGroupedProduct" */ '../products/views/Create/Create.vue').then(
        (module) => module.default,
      ),
    beforeEnter: hasGroupedProducts,
  },
  {
    path: '/products/options',
    name: RoutingRouteEnum.optionsLibrary,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.products.title'],
    },
    component: () =>
      import(/* webpackChunkName: "optionsLibrary" */ '../products/views/Options/Options.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/products/restore',
    name: RoutingRouteEnum.productsRestore,
    meta: {
      title: i18n.messages[i18n.locale]['product.restore.title'],
    },
    component: () =>
      import(/* webpackChunkName: "productsRestore" */ '../products/views/Restore/Restore.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/products/options/:optionId',
    name: RoutingRouteEnum.productOption,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.products.title'],
    },
    component: () =>
      import(/* webpackChunkName: "productOption" */ '../products/views/Options/Option.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/products/:productId',
    name: RoutingRouteEnum.editProduct,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.products.title'],
    },
    component: () =>
      import(/* webpackChunkName: "editProduct" */ '../products/views/Create/Create.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/products/:productId/variant',
    name: RoutingRouteEnum.productVariant,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.products.title'],
    },
    component: () =>
      import(/* webpackChunkName: "productVariant" */ '../products/views/Variant/Variant.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/merchant-growth',
    name: RoutingRouteEnum.MerchantGrowth,
    meta: {
      title: i18n.messages[i18n.locale]['merchant_growth.main_title'],
    },
    component: () =>
      import(
        /* webpackChunkName: "MerchantGrowthMainPage" */ '../merchant-growth-v2/views/merchant-growth/MerchantGrowth.vue'
      ).then((module) => module.default),
  },
  {
    path: '/merchant-growth/registration',
    name: RoutingRouteEnum.MerchantGrowthRegistration,
    meta: {
      title: i18n.messages[i18n.locale]['merchant_growth.registration_title'],
    },
    component: () =>
      import(
        /* webpackChunkName: "MerchantGrowthRegistration" */ '../merchant-growth/views/MerchantGrowthRegistration/MerchantGrowthRegistration'
      ).then((module) => module.MerchantGrowthRegistration),
  },
  {
    path: '/analytics',
    name: RoutingRouteEnum.analyticsV2,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.reports.title2'],
    },
    component: () =>
      import(/* webpackChunkName: "analyticsV2" */ '../analytics/views/Analytics/Analytics').then(
        (module) => module.AnalyticsViewsAnalyticsComponent,
      ),
  },
  {
    path: '/live-analytics',
    name: RoutingRouteEnum.liveAnalytics,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.live_reports.title'],
    },
    component: () =>
      import(/* webpackChunkName: "liveAnalytics" */ '../analytics/views/LiveAnalytics/LiveAnalytics').then(
        (module) => module.AnalyticsViewsLiveAnalyticsComponent,
      ),
    beforeEnter: pilotsGuard,
  },
  {
    path: '/analytics-report',
    name: RoutingRouteEnum.analyticsReports,
    meta: {
      title: i18n.messages[i18n.locale]['common.reports'],
    },
    component: () =>
      import(/* webpackChunkName: "analyticsReports" */ '../analytics/views/Reports/Reports').then(
        (module) => module.AnalyticsViewsAnalyticsReportsComponent,
      ),
  },
  {
    path: '/fai',
    name: RoutingRouteEnum.faiApp,
    meta: {
      title: i18n.messages[i18n.locale]['sidebar.navigation_section.fai_app'],
    },
    component: () =>
      import(/* webpackChunkName: "faiApp" */ '../analytics/views/Fai/FaiViewComponent.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/account/settings/questions-ratings',
    name: RoutingRouteEnum.questionsRatings,
    meta: {
      title: i18n.messages[i18n.locale]['questions-ratings-management.title'],
    },
    component: () =>
      import(
        /* webpackChunkName: "questionsRatings" */ '../questions-ratings-management/views/QuestionsRatingsManagement/QuestionsRatingsManagement'
      ).then((module) => module.QuestionsRatingsManagementViewsComponent),
  },
  {
    path: '/account/settings/constraints-payment-and-shipping',
    name: RoutingRouteEnum.constraintsPaymentAndShipping,
    meta: {
      title: i18n.messages[i18n.locale]['constraints_payment_and_shipping.title'],
    },
    beforeEnter: pilotsGuard,
    component: () =>
      isFeatureAvailable('order_checkout_page.checkout_constraints')
        ? import(
            /* webpackChunkName: "ConstraintsPaymentAndShipping" */ '../settings/views/ConstraintsPaymentAndShipping/ConstraintsPaymentAndShipping'
          ).then((module) => module.ConstraintsPaymentAndShippingSettingsViewsComponent)
        : import(/* webpackChunkName: "UpgradePackageComponent" */ '../app/views/UpgradePackage/UpgradePackage').then(
            (module) => module.UpgradePackageComponent,
          ),
  },
  {
    path: '/account/settings/constraints-payment-and-shipping/create',
    name: RoutingRouteEnum.createConstraint,
    meta: {
      title: i18n.messages[i18n.locale]['constraints_payment_and_shipping.title'],
    },
    component: () =>
      isFeatureAvailable('order_checkout_page.checkout_constraints')
        ? import(
            /* webpackChunkName: "CreateConstraints" */ '../settings/views/ConstraintsPaymentAndShipping/ConstraintsCreation/ConstraintsCreation'
          ).then((module) => module.ConstraintsPaymentAndShippingSettingsViewsAddConstraintComponent)
        : import(/* webpackChunkName: "UpgradePackageComponent" */ '../app/views/UpgradePackage/UpgradePackage').then(
            (module) => module.UpgradePackageComponent,
          ),
  },
  {
    path: '/account/settings/constraints-payment-and-shipping/:constraintId',
    name: RoutingRouteEnum.editConstraint,
    meta: {
      title: i18n.messages[i18n.locale]['constraints.edit'],
    },
    component: () =>
      isFeatureAvailable('order_checkout_page.checkout_constraints')
        ? import(
            /* webpackChunkName: "EditConstraints" */ '../settings/views/ConstraintsPaymentAndShipping/ConstraintsEdit/ConstraintsEdit'
          ).then((module) => module.ConstraintsPaymentAndShippingSettingsViewsEditConstraintComponent)
        : import(/* webpackChunkName: "UpgradePackageComponent" */ '../app/views/UpgradePackage/UpgradePackage').then(
            (module) => module.UpgradePackageComponent,
          ),
  },
  {
    path: '/ratings-notifications',
    name: RoutingRouteEnum.ratingsNotifications,
    meta: {
      title: i18n.messages[i18n.locale]['questions-ratings-management.title'],
    },
    component: () =>
      import(
        /* webpackChunkName: "ratingsNotifications" */ '../questions-ratings-management/views/RatingsNotificationsSettings/RatingsNotificationsSettings'
      ).then((module) => module.RatingsNotificationsSettingsViewsComponent),
  },
  {
    path: '/ratings-questions-messages',
    name: RoutingRouteEnum.ratingsQuestionsMessages,
    meta: {
      title: i18n.messages[i18n.locale]['questions-ratings-management.title'],
    },
    component: () =>
      import(
        /* webpackChunkName: "ratingsQuestionsMessages" */ '../promotional-messages/views/QuestionsAndRatingsMessages/QuestionsAndRatingsMessages'
      ).then((module) => module.QuestionsAndRatingsMessagesViewsComponent),
  },
  {
    path: '/questions-notifications',
    name: RoutingRouteEnum.questionsNotifications,
    meta: {
      title: i18n.messages[i18n.locale]['questions-ratings-management.title'],
    },
    component: () =>
      import(
        /* webpackChunkName: "questionsNotifications" */ '../questions-ratings-management/views/QuestionsNotificationsSettings/QuestionsNotificationsSettings'
      ).then((module) => module.QuestionsNotificationsSettingsViewsComponent),
  },
  {
    path: '/similar-products/:productId',
    name: RoutingRouteEnum.similarProducts,
    meta: {
      title: i18n.messages[i18n.locale]['product.similar_products.title'],
    },
    component: () =>
      import(/* webpackChunkName: "SimilarProducts" */ '../products/views/SimilarProducts/SimilarProducts').then(
        (module) => module.ProductsViewsSimilarProductsComponent,
      ),
  },
  {
    path: '/account/settings/notify-customer',
    name: RoutingRouteEnum.notifyRestockedProduct,
    meta: {
      title: i18n.messages[i18n.locale]['settings.notify_customer_for_restocked_product.title'],
    },
    component: () =>
      import(
        /* webpackChunkName: "NotifyRestockedProduct" */ '../settings/views/NotifyRestockedProduct/NotifyRestockProducts'
      ).then((module) => module.SettingsViewsNotifyCustomersRestockedProductComponent),
  },
  {
    path: '/notify-customer-messages/create',
    name: RoutingRouteEnum.notifyRestockedProductCreateMessage,
    meta: {
      title: i18n.messages[i18n.locale]['settings.notify_customer_for_restocked_product.msg_settings'],
    },
    component: () =>
      import(
        /* webpackChunkName: "NotifyCustomersRestockedProductNotificationViewComponent" */ '../promotional-messages/views/NotifyRestockProducts/NotifyRestockProducts'
      ).then((module) => module.NotifyCustomersRestockedProductNotificationViewComponent),
  },
  {
    path: '/metafields',
    name: RoutingRouteEnum.metafields,
    meta: {
      title: i18n.messages[i18n.locale]['metafields.title'],
    },
    component: () =>
      import(/* webpackChunkName: "metafields" */ '../metafields/views/Metafields.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/add-metafield/:referenceType',
    name: RoutingRouteEnum.addMetafield,
    meta: {
      title: i18n.messages[i18n.locale]['metafields.form.add_title'],
    },
    component: () =>
      import(/* webpackChunkName: "addMetafield" */ '../metafields/views/AddMetafield.vue').then(
        (module) => module.default,
      ),
    beforeEnter: (to: Route, from: Route, next: Function): void => {
      const validTypes = ['product', 'category', 'extra_page'];
      if (validTypes.includes(to.params.referenceType)) {
        next();
      } else {
        next({ name: RoutingRouteEnum.notFound });
      }
    },
  },
  {
    path: '/edit-metafield/:referenceType/:id',
    name: RoutingRouteEnum.editMetafield,
    meta: {
      title: i18n.messages[i18n.locale]['metafields.form.edit_title'],
    },
    component: () =>
      import(/* webpackChunkName: "addMetafield" */ '../metafields/views/AddMetafield.vue').then(
        (module) => module.default,
      ),
    beforeEnter: (to: Route, from: Route, next: Function): void => {
      const validTypes = ['product', 'category', 'extra_page'];
      if (validTypes.includes(to.params.referenceType)) {
        next();
      } else {
        next({ name: RoutingRouteEnum.notFound });
      }
    },
  },
  {
    path: '/pages/add',
    name: RoutingRouteEnum.addExtraPage,
    meta: {
      title: i18n.messages[i18n.locale]['extra_page.form.fields.title'],
    },
    beforeEnter(to: Route, from: Route, next: Function): void {
      if (!isFeatureAvailable('store_design_options.extra_pages')) window.history.back();
      else next();
    },
    component: () =>
      import(/* webpackChunkName: "addMetafield" */ '../settings/views/ExtraPage/ExtraPage.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/pages/edit/:id',
    name: RoutingRouteEnum.editExtraPage,
    meta: {
      title: i18n.messages[i18n.locale]['extra_page.form.fields.title_edit'],
    },
    beforeEnter(to: Route, from: Route, next: Function): void {
      if (!isFeatureAvailable('store_design_options.extra_pages')) window.history.back();
      else next();
    },
    component: () =>
      import(/* webpackChunkName: "addMetafield" */ '../settings/views/ExtraPage/ExtraPage.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/invoice-not-ready',
    name: RoutingRouteEnum.invoiceNotReady,
    component: () =>
      import(/* webpackChunkName: "InvoiceNotReadyComponent" */ '../app/views/InvoiceNotReady/InvoiceNotReady').then(
        (module) => module.AppViewsInvoiceNotReadyComponent,
      ),
  },
  {
    path: '/user-management/users',
    name: RoutingRouteEnum.userManagement,
    meta: {
      title: i18n.messages[i18n.locale]['usermanagement.members'],
    },
    component: () =>
      import(/* webpackChunkName: "moderators" */ '../user-management/view/UserManagementMainLayout/index.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/user-management/permission-groups',
    name: RoutingRouteEnum.permissionGroups,
    meta: {
      title: i18n.messages[i18n.locale]['usermanagement.groups'],
    },
    component: () =>
      import(/* webpackChunkName: "moderators" */ '../user-management/view/UserManagementMainLayout/index.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/user-management/user/create',
    name: RoutingRouteEnum.createUser,
    meta: {
      title: i18n.messages[i18n.locale]['usermanagement.add_new_member'],
    },
    component: () =>
      import(/* webpackChunkName: "createUser" */ '../user-management/view/UserForm/UserForm.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/user-management/user/:id',
    name: RoutingRouteEnum.editUser,
    meta: {
      title: i18n.messages[i18n.locale]['usermanagement.edit_member'],
    },
    component: () =>
      import(/* webpackChunkName: "editUser" */ '../user-management/view/UserForm/UserForm.vue').then(
        (module) => module.default,
      ),
  },
  {
    path: '/user-management/permissions-group/create',
    name: RoutingRouteEnum.createPermissionsGroup,
    meta: {
      title: i18n.messages[i18n.locale]['usermanagement.create_new_group'],
    },
    component: () =>
      import(
        /* webpackChunkName: "createPermissionsGroup" */ '../user-management/view/PermissionsGroupForm/PermissionsGroupForm.vue'
      ).then((module) => module.default),
  },
  {
    path: '/user-management/permissions-group/edit/:id',
    name: RoutingRouteEnum.editPermissionsGroup,
    meta: {
      title: i18n.messages[i18n.locale]['usermanagement.edit_group'],
    },
    component: () =>
      import(
        /* webpackChunkName: "editPermissionsGroup" */ '../user-management/view/PermissionsGroupForm/PermissionsGroupForm.vue'
      ).then((module) => module.default),
  },
  // Keep not found handler at the bottom!
  {
    path: '*',
    name: RoutingRouteEnum.notFound,
    component: () =>
      import(/* webpackChunkName: "notFound" */ '../app/views/NotFound/NotFound').then(
        (module) => module.AppViewsNotFoundComponent,
      ),
  },
];
